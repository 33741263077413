import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import { 
    ADD_CAMPAIGN_DATA,
    GET_CAMPAIGN_DATA,
    GET_CAMPAIGN_MAIL_LIST_DATA,
    GET_CAMPAIGN_NAME_DATA,
    GET_CAMPAIGN_DETAILS_DATA,
    REMOVE_LEAD_DATA,
    EDIT_CAMPAIGN_DATA,
    CAMPAIGN_EMAIL_CHART_DATA,
    GET_CAMPAIGN_LEADS_DATA,
 } from '../actions/actiontypes'

const { REQUEST,  } = actionTypes


//write sagas function
function* handleAddCampaignAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.AddCampaignData,
        data.data,
      )
      yield sendPayload(apiResponse, ADD_CAMPAIGN_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, ADD_CAMPAIGN_DATA)
    }
  }

  function* handleCampaignEmailChartAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getCampaignEmailChartData,
        data.data,
      )
      yield sendPayload(apiResponse, CAMPAIGN_EMAIL_CHART_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, CAMPAIGN_EMAIL_CHART_DATA)
    }
  }

  function* handleEditCampaignAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.EditCampaignData,
        data.data,
      )
      yield sendPayload(apiResponse, EDIT_CAMPAIGN_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, EDIT_CAMPAIGN_DATA)
    }
  }

  function* handleRemoveLeadAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.removeLeadData,
        data.data,
      )
      yield sendPayload(apiResponse, REMOVE_LEAD_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, REMOVE_LEAD_DATA)
    }
  }

  function* handleGetCampaignDataAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getCampaignData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_CAMPAIGN_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_CAMPAIGN_DATA)
    }
  }

  function* handleGetCampaignMailListDataAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getCampaignMailListData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_CAMPAIGN_MAIL_LIST_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_CAMPAIGN_MAIL_LIST_DATA)
    }
  }

  function* handleGetCampaignDetailsDataAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getCampaignDetailsData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_CAMPAIGN_DETAILS_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_CAMPAIGN_DETAILS_DATA)
    }
  }

  function* handleGetCampaignLeadsDataAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getCampaignLeadsData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_CAMPAIGN_LEADS_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_CAMPAIGN_LEADS_DATA)
    }
  }

  function* handleGetCampaignNameDataAPI(data: any) {
    try {
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getCampaignNameData,
        data.data,
      )
      yield sendPayload(apiResponse, GET_CAMPAIGN_NAME_DATA)
    } catch (e) {
      yield sendPayloadFailure(e, GET_CAMPAIGN_NAME_DATA)
    }
  }


export const sagas = {
  //watcher come here
  watchAddCampaignAPI: takeLatest(actionTypes.ADD_CAMPAIGN_DATA[REQUEST], handleAddCampaignAPI),
  watchCampaignEmailAPI: takeLatest(actionTypes.CAMPAIGN_EMAIL_CHART_DATA[REQUEST], handleCampaignEmailChartAPI),
  watchEditCampaignAPI: takeLatest(actionTypes.EDIT_CAMPAIGN_DATA[REQUEST], handleEditCampaignAPI),
  watchRemoveLeadAPI: takeLatest(actionTypes.REMOVE_LEAD_DATA[REQUEST], handleRemoveLeadAPI),
  watchGetCampaignDataAPI: takeLatest(actionTypes.GET_CAMPAIGN_DATA[REQUEST], handleGetCampaignDataAPI),
  watchGetCampaignMailListDataAPI: takeLatest(actionTypes.GET_CAMPAIGN_MAIL_LIST_DATA[REQUEST], handleGetCampaignMailListDataAPI),
  watchGetCampaignDetailsDataAPI: takeLatest(actionTypes.GET_CAMPAIGN_DETAILS_DATA[REQUEST], handleGetCampaignDetailsDataAPI),
  watchGetCampaignLeadsDataAPI: takeLatest(actionTypes.GET_CAMPAIGN_LEADS_DATA[REQUEST], handleGetCampaignLeadsDataAPI),
  watchGetCampaignNameDataAPI: takeLatest(actionTypes.GET_CAMPAIGN_NAME_DATA[REQUEST], handleGetCampaignNameDataAPI),
}
