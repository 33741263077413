import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  RESET,
  ANALYTICS_GRAPH_DATA,
  ANALYTICS_BAR_GRAPH_DATA,
  ANALYTICS_PIE_CHART_DATA,
  TARGET_GAUGE_DATA,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE,  } = actionTypes

const ui = () => {
  const isGettingAnalyticsData = (state = false, action: actions) => {
    switch (action.type) {
      case ANALYTICS_GRAPH_DATA[SUCCESS]:
        return false
      case ANALYTICS_GRAPH_DATA[REQUEST]:
        return true
      case ANALYTICS_GRAPH_DATA[FAILURE]:
        return false
      case ANALYTICS_GRAPH_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingAnalyticsBarData = (state = false, action: actions) => {
    switch (action.type) {
      case ANALYTICS_BAR_GRAPH_DATA[SUCCESS]:
        return false
      case ANALYTICS_BAR_GRAPH_DATA[REQUEST]:
        return true
      case ANALYTICS_BAR_GRAPH_DATA[FAILURE]:
        return false
      case ANALYTICS_BAR_GRAPH_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingTargetChartData = (state = false, action: actions) => {
    switch (action.type) {
      case TARGET_GAUGE_DATA[SUCCESS]:
        return false
      case TARGET_GAUGE_DATA[REQUEST]:
        return true
      case TARGET_GAUGE_DATA[FAILURE]:
        return false
      case TARGET_GAUGE_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingTemperaturePieChartData = (state = false, action: actions) =>{
    switch(action.type){
      case ANALYTICS_PIE_CHART_DATA[SUCCESS]:
        return false
      case ANALYTICS_PIE_CHART_DATA[REQUEST]:
        return true
      case ANALYTICS_PIE_CHART_DATA[FAILURE]:
        return false
      case ANALYTICS_PIE_CHART_DATA[RESET]:
        return false
      default:
        return state 
    }
  }

  return combineReducers({
    isGettingAnalyticsData,
    isGettingAnalyticsBarData,
    isGettingTemperaturePieChartData,
    isGettingTargetChartData,
  })
}

export default ui

export const getAnalytics = (state: RootState) => state.ui.analytics
