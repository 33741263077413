import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  BULK_EMAIL_DATA,
  SEND_EMAIL,
  SEND_EMAIL_CAMPAIGN,
  CREATE_USER_DATA,
  UPDATE_USER_DATA,
  CHANGE_PASSWORD_DATA,
  CREATE_FOLLOW_UP_DATA,
  RESET,
  UPLOAD_FILE,
  CREATE_LEAD_DATA,
  UPDATE_LEAD_DATA,
  SET_TARGET_DATA,
  GET_ADD_USER_DATA,
  GET_EMAIL_DASHBOARD_DATA,
  GET_EMAIL_DETAILS_DATA,
  GET_EMAIL_TRACK_DATA,
  GET_EMAIL_GRAPH_DATA,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE, GET_USER_DATA } = actionTypes

const ui = () => {
    const isEmailSendDone = (state = false, action: actions) => {
      switch (action.type) {
        case SEND_EMAIL[SUCCESS]:
          return true
        case SEND_EMAIL[FAILURE]:
        case SEND_EMAIL[REQUEST]:
          return false
        case SEND_EMAIL[RESET]:
          return false
        default:
          return state
      }
    }

    const resetSendEmail = (state = false, action: actions) => {
      switch (action.type) {
        case SEND_EMAIL[SUCCESS]:
          return false
        case SEND_EMAIL[REQUEST]:
          return false
        case SEND_EMAIL[FAILURE]:
          return false
        case SEND_EMAIL[RESET]:
          return true
        default:
          return state
      }
    }

    const isBulkEmailSendDone = (state = false, action: actions) => {
      switch (action.type) {
        case BULK_EMAIL_DATA[SUCCESS]:
          return true
        case BULK_EMAIL_DATA[FAILURE]:
        case BULK_EMAIL_DATA[REQUEST]:
          return false
        case BULK_EMAIL_DATA[RESET]:
          return false
        default:
          return state
      }
    }

    const isBulkEmailSending = (state = false, action: actions) => {
      switch (action.type) {
        case BULK_EMAIL_DATA[SUCCESS]:
          return false
        case BULK_EMAIL_DATA[FAILURE]:
        case BULK_EMAIL_DATA[REQUEST]:
          return true
        case BULK_EMAIL_DATA[RESET]:
          return false
        default:
          return state
      }
    }

    const resetBulkEmailSend = (state = false, action: actions) => {
      switch (action.type) {
        case BULK_EMAIL_DATA[SUCCESS]:
          return false
        case BULK_EMAIL_DATA[REQUEST]:
          return false
        case BULK_EMAIL_DATA[FAILURE]:
          return false
        case BULK_EMAIL_DATA[RESET]:
          return true
        default:
          return state
      }
    }

    const isCampaignSendEmailDone = (state = false, action: actions) => {
      switch (action.type) {
        case SEND_EMAIL_CAMPAIGN[SUCCESS]:
          return true
        case SEND_EMAIL_CAMPAIGN[FAILURE]:
        case SEND_EMAIL_CAMPAIGN[REQUEST]:
          return false
        case SEND_EMAIL_CAMPAIGN[RESET]:
          return false
        default:
          return state
      }
    }

    const isCampaignEmailSending = (state = false, action: actions) => {
      switch (action.type) {
        case SEND_EMAIL_CAMPAIGN[SUCCESS]:
          return false
        case SEND_EMAIL_CAMPAIGN[FAILURE]:
        case SEND_EMAIL_CAMPAIGN[REQUEST]:
          return true
        case SEND_EMAIL_CAMPAIGN[RESET]:
          return false
        default:
          return state
      }
    }


  const isGetUserDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case GET_USER_DATA[SUCCESS]:
        return true
      case GET_USER_DATA[FAILURE]:
      case GET_USER_DATA[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isCreateUserDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_USER_DATA[SUCCESS]:
        return true
      case CREATE_USER_DATA[FAILURE]:
      case CREATE_USER_DATA[REQUEST]:
      case CREATE_USER_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const isUpdateUserDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case UPDATE_USER_DATA[SUCCESS]:
        return true
      case UPDATE_USER_DATA[FAILURE]:
      case UPDATE_USER_DATA[REQUEST]:
      case UPDATE_USER_DATA[RESET]:   
        return false
      default:
        return state
    }
  }

  const isChangePasswordDataDone = (state = false, action: actions) => {    
    switch (action.type) {
      case CHANGE_PASSWORD_DATA[SUCCESS]:
        return true
      case CHANGE_PASSWORD_DATA[FAILURE]:
      case CHANGE_PASSWORD_DATA[REQUEST]:
      case CHANGE_PASSWORD_DATA[RESET]:   
        return false
      default:
        return state
    }
  }

  const isSetTargetDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case SET_TARGET_DATA[SUCCESS]:
        return true
      case SET_TARGET_DATA[FAILURE]:
      case SET_TARGET_DATA[REQUEST]:
      case SET_TARGET_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isUploadFileDone = (state = false, action: actions) => {
    switch (action.type) {
      case UPLOAD_FILE[SUCCESS]:
        return true
      case UPLOAD_FILE[FAILURE]:
      case UPLOAD_FILE[REQUEST]:
      case UPLOAD_FILE[RESET]:  
        return false
      default:
        return state
    }
  }

  const isUploadingData = (state = false, action: actions) => {
    switch (action.type) {
      case UPLOAD_FILE[SUCCESS]:
        return false
      case UPLOAD_FILE[REQUEST]:
        return true
      case UPLOAD_FILE[FAILURE]:
        return false
      case UPLOAD_FILE[RESET]:
        return false
      default:
        return state
    }
  }

  const resetUploadFile = (state = false, action: actions) => {
    switch (action.type) {
      case UPLOAD_FILE[SUCCESS]:
        return false
      case UPLOAD_FILE[REQUEST]:
        return false
      case UPLOAD_FILE[FAILURE]:
        return false
      case UPLOAD_FILE[RESET]:
        return true
      default:
        return state
    }
  }

  const isGettingAddUserData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_ADD_USER_DATA[SUCCESS]:
        return false
      case GET_ADD_USER_DATA[REQUEST]:
        return true
      case GET_ADD_USER_DATA[FAILURE]:
        return false
      case GET_ADD_USER_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isCreateLeadDone = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_LEAD_DATA[SUCCESS]:
        return true
      case CREATE_LEAD_DATA[FAILURE]:
      case CREATE_LEAD_DATA[REQUEST]:
      case CREATE_LEAD_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const isUpdateLeadDone = (state = false, action: actions) => {
    switch (action.type) {
      case UPDATE_LEAD_DATA[SUCCESS]:
        return true
      case UPDATE_LEAD_DATA[FAILURE]:
      case UPDATE_LEAD_DATA[REQUEST]:
      case UPDATE_LEAD_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const isUpdatingData = (state = false, action: actions) => {
    switch (action.type) {
      case UPDATE_LEAD_DATA[SUCCESS]:
        return false
      case UPDATE_LEAD_DATA[REQUEST]:
        return true
      case UPDATE_LEAD_DATA[FAILURE]:
        return false
      case UPDATE_LEAD_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const resetUpdateLead = (state = false, action: actions) => {
    switch (action.type) {
      case UPDATE_LEAD_DATA[SUCCESS]:
        return false
      case UPDATE_LEAD_DATA[REQUEST]:
        return false
      case UPDATE_LEAD_DATA[FAILURE]:
        return false
      case UPDATE_LEAD_DATA[RESET]:
        return true
      default:
        return state
    }
  }

  const resetCreateLead = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_LEAD_DATA[SUCCESS]:
        return false
      case CREATE_LEAD_DATA[REQUEST]:
        return false
      case CREATE_LEAD_DATA[FAILURE]:
        return false
      case CREATE_LEAD_DATA[RESET]:
        return true
      default:
        return state
    }
  }

  const resetCreateUser = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_USER_DATA[SUCCESS]:
        return false
      case CREATE_USER_DATA[REQUEST]:
        return false
      case CREATE_USER_DATA[FAILURE]:
        return false
      case CREATE_USER_DATA[RESET]:
        return true
      default:
        return state
    }
  }

  const isCreateFollowupDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_FOLLOW_UP_DATA[SUCCESS]:
        return true
      case CREATE_FOLLOW_UP_DATA[FAILURE]:
      case CREATE_FOLLOW_UP_DATA[REQUEST]:
      case CREATE_FOLLOW_UP_DATA[RESET]:  
        return false
      default:
        return state
    }
  }

  const resetCreateFollowupData = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_FOLLOW_UP_DATA[SUCCESS]:
        return false
      case CREATE_FOLLOW_UP_DATA[REQUEST]:
        return false
      case CREATE_FOLLOW_UP_DATA[FAILURE]:
        return false
      case CREATE_FOLLOW_UP_DATA[RESET]:
        return true
      default:
        return state
    }
  }
  

  const isGettingEmailDashboardData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_EMAIL_DASHBOARD_DATA[SUCCESS]:
        return false
      case GET_EMAIL_DASHBOARD_DATA[REQUEST]:
        return true
      case GET_EMAIL_DASHBOARD_DATA[FAILURE]:
        return false
      case GET_EMAIL_DASHBOARD_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingEmailDetailsData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_EMAIL_DETAILS_DATA[SUCCESS]:
        return false
      case GET_EMAIL_DETAILS_DATA[REQUEST]:
        return true
      case GET_EMAIL_DETAILS_DATA[FAILURE]:
        return false
      case GET_EMAIL_DETAILS_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingEmailTrackData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_EMAIL_TRACK_DATA[SUCCESS]:
        return false
      case GET_EMAIL_TRACK_DATA[REQUEST]:
        return true
      case GET_EMAIL_TRACK_DATA[FAILURE]:
        return false
      case GET_EMAIL_TRACK_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingEmailGraphData = (state = false, action: actions) => {
    switch (action.type) {
      case GET_EMAIL_GRAPH_DATA[SUCCESS]:
        return false
      case GET_EMAIL_GRAPH_DATA[REQUEST]:
        return true
      case GET_EMAIL_GRAPH_DATA[FAILURE]:
        return false
      case GET_EMAIL_GRAPH_DATA[RESET]:
        return false
      default:
        return state
    }
  }



  return combineReducers({
    isUploadFileDone,
    isCreateUserDataDone,
    isGettingAddUserData,
    isSetTargetDataDone,
    isUpdateUserDataDone,
    isChangePasswordDataDone,
    isUploadingData,
    resetUploadFile,
    resetCreateUser,
    isEmailSendDone,
    isCampaignSendEmailDone,
    isCampaignEmailSending,
    isBulkEmailSendDone,
    isBulkEmailSending,
    isCreateLeadDone,
    isUpdateLeadDone,
    resetCreateLead,
    resetCreateFollowupData,
    resetSendEmail,
    resetBulkEmailSend,
    isCreateFollowupDataDone,
    resetUpdateLead,
    isGetUserDataDone,
    isUpdatingData,
    isGettingEmailDashboardData,
    isGettingEmailDetailsData,
    isGettingEmailTrackData,
    isGettingEmailGraphData,
  })
}

export default ui

export const getDashboard = (state: RootState) => state.ui.dashboard
