// import "./Contacts.css";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { contactDataEntity, contactDataUI } from "../../reducers";
import moment from "moment";

const EmailDetails = (props: any) => {
  const {
    emailDetailsData,
  } = props;  
  
  const useStyles = makeStyles(() => {
    return createStyles({
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      headingtooltip: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "12px!important",
        fontWeight: "bold",
        wordBreak: "break-word",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginLeft: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
      },
      search: {
        color: '#666666',
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        left: 5,
      },
      mail: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "34px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "100%",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();

  return (
    <>
      <Card
        sx={{
          marginBottom: "10px",
          marginTop: "10px",
          backgroundColor: "rgba(242, 248, 254, 1)",
          boxShadow: "none",
        }}
      >
        <CardContent
          sx={{ padding: "10px 16px", paddingBottom: "5px !important" }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>Email</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>Sender</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>Company</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>Sent From</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>Bounced Date</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.headingtext}>Type</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
                {emailDetailsData.Email || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
              {emailDetailsData.From || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
              {emailDetailsData.company_name || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
              {emailDetailsData.mailFrom || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
              {emailDetailsData?.BouncedAt
                ? moment(emailDetailsData?.BouncedAt).format("MM/DD/YYYY")
                : "NA"}              
            </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.subheadingtext}>
              {emailDetailsData.Type || "NA"}
              </Typography>
            </Grid>
          </Grid>
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingtext}
                    sx={{ paddingTop: "0 !important", marginTop: "10px" }}
                  >
                    Subject
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subheadingtext}>
                  {emailDetailsData.Subject || "NA"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingtext}
                    sx={{ paddingTop: "0 !important", marginTop: "10px" }}
                  >
                    Description
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subheadingtext}>
                  {emailDetailsData.Description || "NA"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.headingtext}
                    sx={{ paddingTop: "0 !important", marginTop: "10px" }}
                  >
                    Details
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subheadingtext}>
                  {emailDetailsData.Details || "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getContactDetailsData: contactDataEntity.getContact(state).getContactDetailsData,
    isGettingContactsDetailsData: contactDataUI.getContacts(state).isGettingContactsDetailsData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailDetails);
