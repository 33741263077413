import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { statusOptions } from './constant';
import dayjs from "dayjs";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import { MenuItem } from "@material-ui/core";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import {
  analyticsDataEntity,
  campaignDataEntity,
  dashboardUI,
  leadDataUI,
  leadsDataEntity,
} from "../../reducers";
import { Key, useEffect, useState } from "react";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import { dashboardEntity } from "../../reducers";
import {
  createFollowUpDataAPICall,
  updateFollowUpDataAPICall,
  getLeadsDataAPICall,
  getLeadsdetailsById,
  updateLeadDataAPICall,
  getDownloadLeadFile,
  getLeadsStatusDataAPICall,
  getAnalyticsNameDataAPICall,
  getCampaignNameAPICall,
} from "../../actions";
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import moment from "moment-timezone";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import SendEmail from "../DealsClosed/SendEmail";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import LeadDetails from "./LeadDetails";
import AddComment from "./AddComment";

const statusTooltipMap: any = {
  Open: "Opened",
  Delivery: "Delivered",
  Click: "Clicked",
  SpamComplaint: "Spammed",
  Bounce: "Bounced",
  SubscriptionChange: "Subscription Changed"
};

const style = {
  dialogStyle: {
    padding: "0.5rem 0",
    position: "fixed",
    top: "40px",
    left: "calc(50% - 480px)",
    height: "fit-content",
    maxHeight: '100vh',
    zIndex: "10000",
    bgcolor: "#fff",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    overflow: "scroll",
    borderRadius: "3px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  bialogBg: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "max(100%,100vh)",
    zIndex: "9999",
    bgcolor: "black",
    opacity: "0.5",
  },
};

const getStatusColor = (status: string) => {
  switch (status) {
    case "New":
      return "#4CAF50";
    case "Dormant":
      return "#FF5252";
    case "Unsubscribe":
      return "#2196F3";
    default:
      return "#000000";
  }
};

const getColorForProbability = (probability: any) => {
  switch (probability) {
    case 0:
      return "#ff0000";
    case 10:
      return "#ff4000";
    case 20:
      return "#ff8000";
    case 30:
      return "#ffbf00";
    case 40:
      return "#009aff";
    case 50:
      return "#bfff00";
    case 60:
      return "#80ff00";
    case 70:
      return "#40ff00";
    case 80:
      return "#00ff00";
    case 90:
      return "#00ff40";
    case 100:
      return "#00ff80";
    default:
      return "#000000";
  }
};

interface SearchData {
  limit: number;
  page: number;
  searchString: string;
  follow_up_status: any;
  domain: any;
  country?: any;
  fromDate?: string;
  toDate?: string;
  userIds?: any;
  temperature?: string;
  source?: string;
  mailFilter?: string;
}

const OuterTableStatus = (props: any) => {
  const {
    rowsData,
    limit,
    userSearch,
    selectedDate,
    selectedToDate,
    filterOptions1,
    selectedFilterOptions,
    isGettingLeadsData,
    getAnalyticsNameDataAPICall,
    getCampaignNameDataAPICall,
    getAnalyticsNameData,
    getCampaignNameData,
    updateLeadDataAPICall,
    DownloadableLeadFile,
    isDownloadableLeadFile,
    resetDownloadLeadFile,
    isBulkEmailSendDone,
    createFollowUpDataAPICall,
    isCreateFollowUpDataDone,
    getLeadsDataAPICall,
    getLeadsDetailsByIdAPICall,
    getLeadsStatusDataAPICall,
    getLeadsStatusData,
    resetCreateFollowUpData,
    resetUpdateLead,
    isGettingLeadsDataById,
    getLeadsDetailsById,
    openSendEmailDialog1,
    openUpdateStatusDialog,
    handleCloseStatusDialog,
    openCampaignDialog,
    handleCloseCampaignDialog,
    openOwnerDialog,
    handleCloseOwnerDialog,
    openSendEmailDialog2,
    isUpdateLeadDone,
    onCheckboxChange,
    onSelectAll,
    apiCallTrigger,
    // handleOpenEmailDialog
  } = props;

  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const columnPreference: any = localStorage.getItem("preference");
  const preference = JSON.parse(columnPreference);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [leadEmail, setLeadEmail] = useState<any>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [isOwnerDialogOpen, setIsOwnerDialogOpen] = useState(false);
  const [isCampaignDialogOpen, setIsCampaignDialogOpen] = useState(false);
  const [statusData, setStatusData] = useState("New");
  const [ownerData, setOwnerData] = useState("");
  const [campaignData, setCampaignData] = useState("");
  const [leadObjectId, setLeadId] = useState();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowIdForEmail, setSelectedRowIdForEmail] = useState<
    string | null
  >(null);
  const [checkedData, setCheckedData] = useState<any>([]);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  const [dealSize, setDealSize] = useState("");

  useEffect(() => {
    const safeSelectedFilterOptions1 = selectedFilterOptions && typeof selectedFilterOptions === 'object' ? selectedFilterOptions : {};

    const selectedCountries: any = [];
    const selectedDomains: any = [];
    const selectedStatuses: any = [];
    const selectedAssignees: any = [];
    const selectedtemperatures: any = [];
    const selectedsources: any = [];
    const selectedEmailStatus: any = [];

    Object.keys(safeSelectedFilterOptions1).forEach(key => {
      const option = safeSelectedFilterOptions1[key];
      if (option.checked) {
        const parent = filterOptions1.find((parent: any) => parent.children?.some((child: any) => child.key === key));
        if (parent) {
          switch (parent.key) {
            case 'country':
              selectedCountries.push(key);
              break;
            case 'domain':
              selectedDomains.push(key);
              break;
            case 'status':
              selectedStatuses.push(key);
              break;
            case 'assignee':
              selectedAssignees.push(key);
              break;
            case 'temperature':
              selectedtemperatures.push(key);
              break;
            case 'source':
              selectedsources.push(key);
              break;
            case 'emailStatus':
              selectedEmailStatus.push(key);
              break;
            default:
              break;
          }
        }
      }
    });

    const searchData: SearchData = {
      limit: limit,
      page: 1,
      // fromDate: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
      // toDate: moment(new Date()).format('YYYY-MM-DD'),
      follow_up_status: selectedStatuses.join(','),
      domain: selectedDomains.join(','),
      country: selectedCountries.join(','),
      userIds: selectedAssignees.join(','),
      temperature: selectedtemperatures.join(','),
      source: selectedsources.join(','),
      searchString: userSearch,
      mailFilter: selectedEmailStatus.join(','),
    };

    if (userSearch === "") {
      searchData.fromDate = selectedDate;
      searchData.toDate = selectedToDate;
    }

    if (isCreateFollowUpDataDone || isUpdateLeadDone) {
      getLeadsDataAPICall(searchData);
      if (isCreateFollowUpDataDone) {
        setIsEditDialogOpen(false);
        toast.success("Comment added successfully");
        resetCreateFollowUpData();
      }
      resetUpdateLead();
      setSelectedRows([]);
      setCheckedData([]);
    }
  }, [isCreateFollowUpDataDone, isUpdateLeadDone]);

  useEffect(() => {
    if (openSendEmailDialog1) {
      setOpenSendEmailDialog(true);
    } else if (openUpdateStatusDialog) {
      setIsStatusDialogOpen(true);
    } else if (openOwnerDialog) {
      getAnalyticsNameDataAPICall();
      setIsOwnerDialogOpen(true);
    } else if (openCampaignDialog) {
      getCampaignNameDataAPICall();
      setIsCampaignDialogOpen(true);
    }
  }, [
    openSendEmailDialog1,
    openUpdateStatusDialog,
    openCampaignDialog,
    openOwnerDialog,
  ]);

  useEffect(() => {
    if (isDownloadableLeadFile) {
      const link = document.createElement("a");
      link.href = DownloadableLeadFile?.downloadableUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resetDownloadLeadFile();
    }
  }, [isDownloadableLeadFile]);

  useEffect(() => {
    if (statusData === "Won" && !dealSize) {
      setIsUpdateDisabled(true);
    } else {
      setIsUpdateDisabled(false);
    }
  }, [statusData, dealSize]);

  const useStyles = makeStyles(() => {
    return createStyles({
      datepicker: {
        width: "240px",
      },
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      timepicker: {
        width: "170px",
        marginLeft: "10px!important",
      },
      updateButton: {
        display: "block",
        width: "100px",
      },
      updateButtonContainer: {
        display: "flex",
        justifyContent: "right",
        marginTop: "10px",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      headingtooltip: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "12px!important",
        fontWeight: "bold",
        wordBreak: "break-word",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  const classes = useStyles();

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const handleAddIconClick = (email: any, leadId: any, status: any) => {
    setLeadEmail(email);
    setLeadId(leadId);
    setStatusData(status);
    setIsEditDialogOpen(true);
  };
  const handleChangeForStatus = (event: any) => {
    const newValue = event.target.value;
    setStatusData(newValue);
  };
  const handleChangeForOwner = (event: any) => {
    const newValue = event.target.value;
    setOwnerData(newValue);
  };
  const handleChangeForCampaign = (event: any) => {
    const newValue = event.target.value;
    setCampaignData(newValue);
  };

  const handleChangeDealSize = (e: any) => {
    let value = e.target.value;

    if (!isNaN(value)) {
      setDealSize(value);
    }
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    handleResetError();
  };

  const handleStatusDialogClose = () => {
    setIsStatusDialogOpen(false);
    handleCloseStatusDialog(false);
  };

  const handleOwnerDialogClose = () => {
    setIsOwnerDialogOpen(false);
    handleCloseOwnerDialog(false);
  };

  const handleCampaignDialogClose = () => {
    setIsCampaignDialogOpen(false);
    handleCloseCampaignDialog(false);
  };

  const handleRowClick = (id: any, fromEmailClick = false) => {
    let data = {
      leadId: id,
    };
    getLeadsDetailsByIdAPICall(data);
    setOpenDialog(true);
    setLeadId(data.leadId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSendEmailCloseDialog = () => {
    setOpenSendEmailDialog(false);
    openSendEmailDialog2(false);
  };

  const handleEditLeadClick = (event: any, id: any) => {
    event.stopPropagation();
    sessionStorage.setItem("selectedDate", selectedDate);
    sessionStorage.setItem("selectedToDate", selectedToDate);
    sessionStorage.setItem("selectedFilterOptions", JSON.stringify(selectedFilterOptions) || "{}");
    sessionStorage.setItem("userSearch", JSON.stringify(userSearch));
    navigate(`/home/dashboard/editlead/${id}`);
  };

  const selectedRow = rowsData?.find(
    (row: { id: string }) => row.id === selectedRowIdForEmail
  );
  const selectedEmailNames = selectedRow ? [selectedRow.first_name] : [];
  const selectedMailEmails = selectedRow ? [selectedRow.email] : [];

  const handleResetError = () => {
    setDealSize("");
    setStatusData("New");
  };

  const handleUpdateStatus = () => {
    const leadStatusData = {
      id: [...selectedRows],
      follow_up_status: statusData,
      deal_size: dealSize || 0,
    };
    if (leadStatusData) {
      updateLeadDataAPICall(leadStatusData);
      setIsStatusDialogOpen(false);
      handleResetError();
    }
  };

  const handleUpdateOwner = () => {
    const OwnerData = {
      id: [...selectedRows],
      ownerName: ownerData,
    };
    if (OwnerData) {
      updateLeadDataAPICall(OwnerData);
      setIsOwnerDialogOpen(false);
    }
  };

  const handleAddToCampaign = () => {
    const OwnerData = {
      usersIds: [...selectedRows],
      campaignId: campaignData,
    };
    if (OwnerData) {
      updateLeadDataAPICall(OwnerData);
      setIsCampaignDialogOpen(false);
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    const isChecked = event.target.checked;
    let data = { id: id, checked: isChecked };
    let tempData = checkedData;
    if (isChecked) {
      onCheckboxChange(isChecked);
      setCheckedData([...checkedData, data]);
    } else {
      tempData = tempData.filter((dd: any) => dd.id !== id);
      setCheckedData(tempData);
      if (tempData.length) {
        onCheckboxChange(true);
      } else {
        onCheckboxChange(false);
      }
    }

    setSelectedRows((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, id];
      } else {
        return prevSelected.filter((rowId) => rowId !== id);
      }
    });
  };

  useEffect(() => {
    setSelectedRows([]);
    setCheckedData([]);
  }, [apiCallTrigger, isBulkEmailSendDone]);

  // const handleSelectAllClick = (event: any) => {
  //   const isCheckedAll = event.target.checked;
  //   onSelectAll(isCheckedAll);
  //   const allRowIds = rowsData?.map((row: { id: any }) => row.id);
  //   const checkedData: any = [];
  //   if (isCheckedAll) {
  //     rowsData?.forEach((row: any) => {
  //       let data = {
  //         id: row.id,
  //         checked: isCheckedAll,
  //       };
  //       checkedData.push(data);
  //     });
  //     setCheckedData(checkedData);
  //     onCheckboxChange(true);
  //   } else {
  //     setCheckedData([]);
  //     onCheckboxChange(false);
  //   }

  //   setSelectedRows((prevSelected) => {
  //     if (prevSelected?.length === allRowIds?.length) {
  //       return [];
  //     } else {
  //       return allRowIds;
  //     }
  //   });
  // };

  const handleSelectAllClick = (event: any) => {
    const isCheckedAll = event.target.checked;
    const selectableRows = rowsData?.filter(
      (row: { follow_up_status: string }) => row.follow_up_status !== "Unsubscribe"
    );
    const selectableRowIds = selectableRows?.map((row: { id: any }) => row.id);

    if (isCheckedAll) {
      const checkedData = selectableRows.map((row: any) => ({
        id: row.id,
        checked: true,
      }));
      setCheckedData(checkedData);
      onCheckboxChange(true);
      setSelectedRows(selectableRowIds); // Only set selectable row IDs
    } else {
      setCheckedData([]);
      onCheckboxChange(false);
      setSelectedRows([]); // Clear selected rows
    }
  };

  const leadTooltipContent = (data: any) => {
    return (
      <div style={{ padding: "8px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40px",
              height: "40px",
              backgroundColor: "brown",
              borderRadius: "50%",
              color: "white",
              fontSize: "20px",
              marginRight: "10px",
            }}
          >
            {data.name ? data.name.charAt(0).toUpperCase() : "N"}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography className={classes.titletext}>
              {data.name || "NA"}
            </Typography>
            {data.lead_designation && (
              <Typography className={classes.subheadingtext}>
                {data.lead_designation || "NA"}
              </Typography>
            )}
          </div>
        </div>
        <Divider className="tooltip-divider" />
        <Typography
          className={classes.subheadingtext}
          sx={{ whiteSpace: "pre-line" }}
        >
          {data.leadPhone && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ minWidth: "60px" }}
                className={classes.headingtooltip}
              >
                Phone:
              </span>
              {data.leadPhone || "NA"}
            </div>
          )}
          {data.email && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ minWidth: "60px" }}
                className={classes.headingtooltip}
              >
                Email:
              </span>
              <span style={{ whiteSpace: "nowrap" }}>{data.email || "NA"}</span>
            </div>
          )}
          {data.source && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ minWidth: "60px" }}
                className={classes.headingtooltip}
              >
                Source:
              </span>
              {data.source || "NA"}
            </div>
          )}
          {data.ownerName && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ minWidth: "60px" }}
                className={classes.headingtooltip}
              >
                Owner:
              </span>
              {data.ownerName || "NA"}
            </div>
          )}
        </Typography>
      </div>
    );
  };

  const companyTooltipContent = (data: any) => {
    return (
      <div style={{ padding: "8px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              backgroundColor: "red",
              borderRadius: "50%",
              color: "white",
              fontSize: "20px",
              marginRight: "10px",
            }}
          >
            {data.company ? data.company.charAt(0).toUpperCase() : "N"}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography className={classes.titletext}>
              {data.company || "NA"}
            </Typography>
          </div>
        </div>
        <Divider className="tooltip-divider" />
        <Typography
          className={classes.subheadingtext}
          sx={{ whiteSpace: "pre-line" }}
        >
          {data.companyPhone && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ minWidth: "60px" }}
                className={classes.headingtooltip}
              >
                Phone:
              </span>
              {data.companyPhone || "NA"}
            </div>
          )}
          {data.website && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ minWidth: "60px" }}
                className={classes.headingtooltip}
              >
                Website:
              </span>
              <span style={{ whiteSpace: "nowrap" }}>
                {data.website || "NA"}
              </span>
            </div>
          )}
          {data.domain && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ minWidth: "60px" }}
                className={classes.headingtooltip}
              >
                Domain:
              </span>{" "}
              {data.domain || "NA"}
            </div>
          )}
        </Typography>
      </div>
    );
  };

  const handleCellClick = (event: any) => {
    event.stopPropagation();
  };

  return (
    <>
      <div style={{ overflowX: "auto", maxWidth: "100%", minHeight: "300px" }}>
        <TableContainer>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
            >
              <TableRow>

                {(rowsData?.length) ? (
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      checked={selectedRows?.length === rowsData?.length}
                      onChange={(event) => handleSelectAllClick(event)}
                    />
                  </TableCell>
                ) : ""}
                <>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Lead Name</span>
                  </TableCell>
                  {(preference?.find((item: any) => item.key === 'lead_designation' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Designation</span>
                    </TableCell>
                  ))}
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Email</span>
                  </TableCell>
                  {(preference?.find((item: any) => item.key === 'leadPhone' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Phone</span>
                    </TableCell>
                  ))}
                  {(preference?.find((item: any) => item.key === 'owner' && item.value) && (role === "sales_manager" || role === "admin") && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Owner</span>
                    </TableCell>
                  ))}
                  {(preference?.find((item: any) => item.key === 'leads_country' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Country</span>
                    </TableCell>
                  ))}
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Company</span>
                  </TableCell>
                  {(preference?.find((item: any) => item.key === 'domain' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Domain</span>
                    </TableCell>
                  ))}
                  {(preference?.find((item: any) => item.key === 'source' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Source</span>
                    </TableCell>
                  ))}
                  {(preference?.find((item: any) => item.key === 'temperature' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Temperature</span>
                    </TableCell>
                  ))}
                  {(preference?.find((item: any) => item.key === 'follow_up_status' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Status</span>
                    </TableCell>
                  ))}
                  {(preference?.find((item: any) => item.key === 'probability' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Probability</span>
                    </TableCell>
                  ))}
                  {(preference?.find((item: any) => item.key === 'followUpsNextDate' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Next FollowUp Date</span>
                    </TableCell>
                  ))}
                  {(preference?.find((item: any) => item.key === 'followUpsDate' && item.value) && (
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Created Date</span>
                    </TableCell>
                  ))}
                </>
                <TableCell sx={{ padding: "6px 16px", width: 'auto' }}>
                  <span className="mail-info">Actions</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData?.length ? (
                rowsData?.map((data: any, index: Key | null | undefined) => (
                  <TableRow
                    key={index}
                    sx={{ cursor: "pointer", background: "#ffffff" }}
                    onClick={() => handleRowClick(data.id)}
                  >
                    <TableCell
                      onClick={handleCellClick}
                      sx={{ padding: "0px 16px" }}
                    >
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={selectedRows.includes(data.id)}
                        disabled={data?.follow_up_status === "Unsubscribe"}
                        onChange={(event) => {
                          if (data?.follow_up_status !== "Unsubscribe") {
                            handleCheckboxChange(event, data.id);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell className="tablebodycell">
                      <Tooltip
                        title={leadTooltipContent(data)}
                        placement="right"
                        classes={{
                          tooltip: "custom-tooltip",
                          arrow: "custom-tooltip-arrow",
                        }}
                        arrow
                      >
                        <span>{data?.name ? data?.name : ""}</span>
                      </Tooltip>
                    </TableCell>
                    <>
                      {(preference?.find((item: any) => item.key === 'lead_designation' && item.value) && (
                        <TableCell className="tablebodycell">
                          {data?.lead_designation ?
                            (data.lead_designation.length > 30 ? `${data.lead_designation.slice(0, 30)}...` : data.lead_designation)
                            : "-"}
                        </TableCell>
                      ))}
                      <TableCell className="tablebodycell">
                        {data?.email ?
                          (data.email.length > 30 ? `${data.email.slice(0, 30)}...` : data.email)
                          :
                          "-"}
                      </TableCell>
                      {(preference?.find((item: any) => item.key === 'leadPhone' && item.value) && (
                        <TableCell className="tablebodycell">
                          {data?.leadPhone ? data?.leadPhone : "-"}
                        </TableCell>
                      ))}
                      {(preference?.find((item: any) => item.key === 'owner' && item.value) && (role === "sales_manager" || role === "admin") && (
                        <TableCell className="tablebodycell">
                          {data?.ownerName ? data?.ownerName : "-"}
                        </TableCell>
                      ))}
                      {(preference?.find((item: any) => item.key === 'leads_country' && item.value) && (
                        <TableCell className="tablebodycell">
                          {data?.leads_country ? data?.leads_country : "-"}
                        </TableCell>
                      ))}
                      <TableCell className="tablebodycell">
                        <Tooltip
                          title={companyTooltipContent(data)}
                          placement="right"
                          classes={{
                            tooltip: "custom-tooltip",
                            arrow: "custom-tooltip-arrow",
                          }}
                          arrow
                        >
                          <span>
                            {data?.company ?
                              (data.company.length > 30 ? `${data.company.slice(0, 30)}...` : data.company)
                              :
                              "-"}
                          </span>
                        </Tooltip>
                      </TableCell>
                      {(preference?.find((item: any) => item.key === 'domain' && item.value) && (
                        <TableCell className="tablebodycell">
                          {data?.domain ? data?.domain : "-"}
                        </TableCell>
                      ))}
                      {(preference?.find((item: any) => item.key === 'source' && item.value) && (
                        <TableCell className="tablebodycell">
                          {data?.source ? data?.source : "-"}
                        </TableCell>
                      ))}
                      {(preference?.find((item: any) => item.key === 'temperature' && item.value) && (
                        <TableCell className="tablebodycell">
                          {data?.temperature ? data?.temperature : "-"}
                        </TableCell>
                      ))}
                      {(preference?.find((item: any) => item.key === 'follow_up_status' && item.value) && (
                        <TableCell
                          className="tablebodycell"
                          sx={{ color: getStatusColor(data.follow_up_status) }}
                        >
                          {data?.follow_up_status || "-"}
                        </TableCell>
                      ))}
                      {(preference?.find((item: any) => item.key === 'probability' && item.value) && (
                        <TableCell
                          className="tablebodycell"
                          sx={{ color: getColorForProbability(data.probability) }}
                        >
                          {/* {`${data.probability}%` || "-"} */}
                          {data?.probability !== undefined &&
                            data?.probability !== null
                            ? `${data.probability}%`
                            : "-"}
                        </TableCell>
                      ))}
                      {(preference?.find((item: any) => item.key === 'followUpsNextDate' && item.value) && (
                        <TableCell className="tablebodycell">
                          <span
                            className={`arrow-ribbon ${moment(data?.followUpsNextDate)
                                .utc()
                                .isSame(moment().utc(), "day")
                                ? "today"
                                : moment(data?.followUpsNextDate)
                                  .utc()
                                  .isSame(moment().utc().add(1, "day"), "day")
                                  ? "tomorrow"
                                  : data?.followUpsNextDate
                                    ? "other-date"
                                    : ""
                              }`}
                          >
                            {data?.followUpsNextDate
                              ? moment
                                .utc(data.followUpsNextDate)
                                .isSame(moment().utc(), "day")
                                ? "Today"
                                : moment
                                  .utc(data.followUpsNextDate)
                                  .isSame(moment().utc().add(1, "day"), "day")
                                  ? "Tomorrow"
                                  : moment
                                    .utc(data.followUpsNextDate)
                                    .format("DD MMM YY")
                              : "NA"}
                          </span>
                        </TableCell>
                      ))}
                      {(preference?.find((item: any) => item.key === 'followUpsDate' && item.value) && (
                        <TableCell className="tablebodycell">
                          {data?.followUpsDate
                            ? moment(data?.followUpsDate).format("MM/DD/YYYY")
                            : "NA"}
                        </TableCell>
                      ))}
                    </>
                    <TableCell
                      onClick={handleCellClick}
                      sx={{
                        fontFamily: "Montserrat-Medium",
                        fontSize: "12px",
                        padding: "0px 16px",
                        width: 'auto'
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                          onClick={(event) => {
                            stopPropagation(event);
                            handleAddIconClick(
                              data?.email,
                              data?.id,
                              data?.follow_up_status
                            );
                          }}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            paddingLeft: "0px",
                            paddingTop: "3px",
                            paddingRight: "2px",
                          }}
                        >
                          <Tooltip title="Add Comment">
                            <ModeCommentOutlinedIcon
                              color="primary"
                              sx={{ marginTop: "5px" }}
                              className={classes.actionbtn}
                            />
                          </Tooltip>
                        </button>
                        <button
                          onClick={(event) =>
                            handleEditLeadClick(event, data.id)
                          }
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            padding: "0",
                          }}
                        >
                          <Tooltip title="Edit Lead">
                            <EditOutlinedIcon
                              sx={{ marginTop: "5px", color: "#4CAF50" }}
                              className={classes.actionbtn}
                            />
                          </Tooltip>
                        </button>
                        <Tooltip title={data?.source ? data?.source : "-"}>
                          <AttachMoneyOutlinedIcon
                            // sx={{ marginTop: "5px" }}
                            className={classes.actionbtn}
                          />
                        </Tooltip>
                        {/* <DeleteOutlinedIcon
                              className={classes.actionbtn}
                              sx={{ color: '#ff0000' }}
                            /> */}
                        {/* {data?.status?.map((status: string, index: number) => ( */}
                        <Tooltip key={index} title={statusTooltipMap[data?.status]}>
                          <IconButton
                            size="small"
                            sx={{ padding: 0, marginLeft: "5px", }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {data?.status === "Open" && (
                              <VisibilityOutlinedIcon sx={{ color: "#2196F3", fontSize: "18px" }} />
                            )}
                            {data?.status === "Delivery" && (
                              <LocalShippingOutlinedIcon sx={{ color: "#4CAF50", fontSize: "18px" }} />
                            )}
                            {data?.status === "Click" && (
                              <AdsClickOutlinedIcon sx={{ color: "#FF9800", fontSize: "18px" }} />
                            )}
                            {data?.status === "SpamComplaint" && (
                              <ReportProblemOutlinedIcon sx={{ color: "#FF9800", fontSize: "18px" }} />
                            )}
                            {data?.status === "Bounce" && (
                              <ErrorOutlineOutlinedIcon sx={{ color: "#F44336", fontSize: "18px" }} />
                            )}
                            {data?.status === "SubscriptionChange" && (
                              <SubscriptionsOutlinedIcon sx={{ color: "#F44336", fontSize: "18px" }} />
                            )}
                          </IconButton>
                        </Tooltip>
                        {/* ))} */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12}>
                    {isGettingLeadsData ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "120px",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    ) : (
                      <div className="no-data-outer-table">
                        No data available.
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog
        open={isEditDialogOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "55%",
            maxWidth: "55%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle className={classes.addcomment}>
          Add Comment
          <IconButton
            aria-label="close"
            onClick={handleEditDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent className="comment-dialog">
          <AddComment
            leadEmail={leadEmail}
            statusData={statusData}
            leadObjectId={leadObjectId}
            handleEditDialogClose={handleEditDialogClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle className={classes.titletext} sx={{ marginLeft: "45%" }}>
          Lead Details
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ paddingTop: "10px" }}>
          <LeadDetails leadObjectId={leadObjectId} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isStatusDialogOpen}
        onClose={handleSendEmailCloseDialog}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "30%",
            maxWidth: "30%",
          },
        }}
      >
        <DialogTitle className={classes.addcomment}>
          Update Status
          <IconButton
            aria-label="close"
            onClick={handleStatusDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <div style={{ width: "100%", marginTop: "6px" }}>
            <TextField
              select
              id="demo-simple-select"
              value={statusData}
              label="Status"
              variant="outlined"
              onChange={handleChangeForStatus}
              InputProps={{ sx: { borderRadius: 20 } }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: "300px",
                    },
                  },
                },
              }}
            >
              {statusOptions.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="deal-size"
              value={dealSize}
              label="Deal Size ($)"
              variant="outlined"
              InputLabelProps={{
                style: { top: "-6px" },
              }}
              InputProps={{ sx: { borderRadius: 20 }, }}
              onChange={handleChangeDealSize}
              required={statusData === "Won"}
              sx={{ marginTop: 2, borderRadius: 20 }}
            />

            <div className={classes.updateButtonContainer}>
              <Button
                className={classes.cancelbtn}
                // variant='contained'
                color="primary"
                onClick={() => handleStatusDialogClose()}
              >
                Cancel
              </Button>
              <Button
                className={classes.updateButton}
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  fontFamily: "Montserrat-SemiBold",
                  fontSize: "14px",
                }}
                color="primary"
                onClick={() => handleUpdateStatus()}
                disabled={isUpdateDisabled}
              >
                Update
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isOwnerDialogOpen}
        onClose={handleOwnerDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "30%",
            maxWidth: "30%",
          },
        }}
      >
        <DialogTitle className={classes.addcomment}>
          Update Owner
          <IconButton
            aria-label="close"
            onClick={handleOwnerDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <div style={{ width: "100%", marginTop: "6px" }}>
            <TextField
              select
              id="demo-simple-select"
              value={ownerData}
              label="Owner"
              variant="outlined"
              onChange={handleChangeForOwner}
              InputProps={{ sx: { borderRadius: 20 } }}
              InputLabelProps={{
                style: { top: "-6px" },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: "300px",
                    },
                  },
                },
              }}
            >
              {getAnalyticsNameData?.names?.map((data: any) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.first_name}
                </MenuItem>
              ))}
            </TextField>
            <div className={classes.updateButtonContainer}>
              <Button
                className={classes.cancelbtn}
                // variant='contained'
                color="primary"
                onClick={() => handleOwnerDialogClose()}
              >
                Cancel
              </Button>
              <Button
                className={classes.updateButton}
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  fontFamily: "Montserrat-SemiBold",
                  fontSize: "14px",
                }}
                color="primary"
                onClick={() => handleUpdateOwner()}
                disabled={isUpdateDisabled}
              >
                Update
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCampaignDialogOpen}
        onClose={handleCampaignDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "30%",
            maxWidth: "30%",
          },
        }}
      >
        <DialogTitle className={classes.addcomment}>
          Add to Existing Campaign
          <IconButton
            aria-label="close"
            onClick={handleCampaignDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <div style={{ width: "100%", marginTop: "6px" }}>
            <TextField
              select
              id="demo-simple-select"
              value={campaignData}
              label="Campaign"
              variant="outlined"
              onChange={handleChangeForCampaign}
              InputProps={{ sx: { borderRadius: 20 } }}
              InputLabelProps={{
                style: { top: "-6px" },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: "300px",
                    },
                  },
                },
              }}
            >
              {getCampaignNameData?.data?.map((data: any) => (
                <MenuItem key={data.name} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
              {getCampaignNameData?.data?.length < 0 && (
                <MenuItem disabled>
                  No data available
                </MenuItem>
              )}
            </TextField>
            <div className={classes.updateButtonContainer}>
              <Button
                className={classes.cancelbtn}
                // variant='contained'
                color="primary"
                onClick={() => handleCampaignDialogClose()}
              >
                Cancel
              </Button>
              <Button
                className={classes.updateButton}
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  fontFamily: "Montserrat-SemiBold",
                  fontSize: "14px",
                }}
                color="primary"
                onClick={() => handleAddToCampaign()}
              >
                Update
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {openSendEmailDialog && (
        <>
          <Box
            sx={{
              ...style.bialogBg,
              display: openSendEmailDialog ? "block" : "none",
            }}
          ></Box>
          <Box
            sx={{
              ...style.dialogStyle,
              display: openSendEmailDialog ? "block" : "none",
            }}
          >
            <DialogTitle
              sx={{ padding: "0 15px 10px !important" }}
              className={classes.addcomment}
            >
              Send Email
              <IconButton
                aria-label="close"
                onClick={handleSendEmailCloseDialog}
                sx={{
                  position: "absolute !important",
                  right: '15px',
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="divider" />
            <SendEmail
              selectedNames={selectedRows?.map((rowId) => {
                const row = rowsData?.find(
                  (row: { id: string }) => row.id === rowId
                );
                return row?.first_name || "";
              })}
              selectedEmails={selectedRows?.map((rowId) => {
                const row = rowsData?.find(
                  (row: { id: string }) => row.id === rowId
                );
                return row?.email || "";
              })}
              selectedEmailNames={selectedEmailNames}
              selectedMailEmails={selectedMailEmails}
              handleClose={handleSendEmailCloseDialog}
            />
          </Box>
        </>
      )}
    </>
  );
  // }
};

const mapStateToProps = (state: any) => {
  return {
    isUpdateFollowUpDataDone:
      dashboardEntity.getDashboard(state).isUpdateFollowUpDataDone,
    isCreateFollowUpDataDone:
      dashboardUI.getDashboard(state).isCreateFollowupDataDone,
    isCreateFollowUpData:
      dashboardEntity.getDashboard(state).isCreateFollowUpData,
    getLeadsData: leadsDataEntity.getLeads(state).getLeadsData,
    getLeadsStatusData: leadsDataEntity.getLeads(state).getLeadsStatusData,
    getLeadsDetailsById: leadsDataEntity.getLeads(state).getLeadsDetailsById,
    DownloadableLeadFile: leadsDataEntity.getLeads(state).getDownloadLeadFile,
    isGettingLeadsDataById: leadDataUI.getLeads(state).isGettingLeadsDataById,
    isGettingLeadsData: leadDataUI.getLeads(state).isGettingLeadsData,
    isBulkEmailSending: dashboardUI.getDashboard(state).isBulkEmailSending,
    isBulkEmailSendDone: dashboardUI.getDashboard(state).isBulkEmailSendDone,
    isUpdateLeadDone: dashboardUI.getDashboard(state).isUpdateLeadDone,
    isDownloadableLeadFile: leadDataUI.getLeads(state).isDownloadableLeadFile,
    getCampaignNameData:
      campaignDataEntity.getCampaign(state).getCampaignNameData,
    getAnalyticsNameData:
      analyticsDataEntity.getAnalyticsGraph(state).getAnalyticsNameData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getLeadsDataAPICall: (data: any) =>
      dispatch(getLeadsDataAPICall.request(data)),
    getLeadsStatusDataAPICall: (data: any) =>
      dispatch(getLeadsStatusDataAPICall.request(data)),
    updateFollowUpDataAPICall: (data: any) =>
      dispatch(updateFollowUpDataAPICall.request(data)),
    createFollowUpDataAPICall: (data: any) =>
      dispatch(createFollowUpDataAPICall.request(data)),
    getLeadsDetailsByIdAPICall: (data: any) =>
      dispatch(getLeadsdetailsById.request(data)),
    getDownloadLeadFileURL: (data: any) =>
      dispatch(getDownloadLeadFile.request(data)),
    updateLeadDataAPICall: (data: any) =>
      dispatch(updateLeadDataAPICall.request(data)),
    getAnalyticsNameDataAPICall: (data: any) =>
      dispatch(getAnalyticsNameDataAPICall.request(data)),
    getCampaignNameDataAPICall: (data: any) =>
      dispatch(getCampaignNameAPICall.request(data)),
    resetCreateFollowUpData: () => dispatch(createFollowUpDataAPICall.reset()),
    resetUpdateFollowUpDataAPICall: () =>
      dispatch(updateFollowUpDataAPICall.reset()),
    resetUpdateLead: () => dispatch(updateLeadDataAPICall.reset()),
    resetDownloadLeadFile: () => dispatch(getDownloadLeadFile.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OuterTableStatus);
