import {
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AnalyticsGraph from "./AnalyticsGraph";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import dayjs from "dayjs";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  analyticsDataEntity,
  analyticsDataUI,
  dashboardEntity,
  dashboardUI,
} from "../../reducers";
import {
  getAnalyticsBarGraphDataAPICall,
  getAnalyticsGraphDataAPICall,
  getAnalyticsNameDataAPICall,
  getEmailGraphAPICall,
  getTargetGaugeDataAPICall,
  getTemperaturePieChartDataAPICall,
} from "../../actions";
import { connect } from "react-redux";
import AnalyticsStatusGraph from "./AnalyticsStatusGraph";
import AnalyticsBarGraph from "./AnalyticsBarGraph";
import TargetChart from "./TargetChart";
import TemperatureChart from "./TemperatureChart";
import TrackEmailChart from "./TrackEmailChart";

const Analytics = (props: any) => {
  const {
    getAnalyticsGraphDataAPICall,
    isGettingAnalyticsData,
    getAnalyticsGraphData,
    getAnalyticsBarGraphData,
    getAnalyticsBarGraphDataAPICall,
    getAnalyticsNameDataAPICall,
    getAnalyticsNameData,
    getTargetGaugeDataAPICall,
    getTargetGaugeData,
    getTemperaturePieChartData,
    getTemperaturePieChartDataApiCall,
    getEmailGraphAPICall,
    getEmailGraphData,
    isGettingEmailGraphData,
    isGettingTemperaturePieChartData,
    isGettingTargetChartData,
  } = props;

  const currentMonth = moment().month();
  let financialYearStartMonth = 3; // Financial year starts in April (0-indexed)
  let financialYearStartYear = moment().year();
  if (currentMonth < financialYearStartMonth) {
    // If the current month is before April, financial year starts in the previous year
    financialYearStartYear -= 1;
  }

  const financialYearStartDate = moment()
    .year(financialYearStartYear)
    .month(financialYearStartMonth)
    .startOf("month")
    .format("YYYY-MM-DD");

  const financialYearEndDate = moment()
    .year(financialYearStartYear + 1)
    .month(financialYearStartMonth - 1)
    .endOf("month")
    .format("YYYY-MM-DD");

  const [selectedName, setSelectedName] = useState("");
  const [selectedTargetName, setSelectedTargetName] = useState("");
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");

  const [selectedDate, setSelctedDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [selectedToDate, setToSelctedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [selectedToDate1, setSelectedToDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectedPieChartStartDate, setSelectedPieChartStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [selectedPieChartEndDate, setSelectedPieChartEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectedFromDate1, setSelectedFromDate1] = useState(
    financialYearStartDate
  );
  const [selectedToDate2, setSelectedToDate2] = useState(financialYearEndDate);
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    const searchData = {
      fromDate: selectedDate,
      toDate: selectedToDate,
    };
    getAnalyticsGraphDataAPICall(searchData);
    getAnalyticsNameDataAPICall();
    const TargetGraphData = {
      startDate: selectedFromDate1,
      endDate: selectedToDate2,
    };
    getTargetGaugeDataAPICall(TargetGraphData);
    if (selectedFromDate && selectedToDate1 && selectedName) {
      const barGraphData = {
        fromDate: selectedFromDate,
        toDate: selectedToDate1,
        id: selectedName,
      };
      getAnalyticsBarGraphDataAPICall(barGraphData);
    }
    if (
      selectedPieChartStartDate &&
      selectedPieChartEndDate &&
      selectedSalesPerson
    ) {
      const pieGraphData = {
        fromDate: selectedPieChartStartDate,
        toDate: selectedPieChartEndDate,
        id: selectedSalesPerson,
      };
      getTemperaturePieChartDataApiCall(pieGraphData);
      getEmailGraphAPICall(pieGraphData);
    }
    // const TargetGraphData = {
    //   startDate: selectedFromDate1,
    //   endDate: selectedToDate2,
    //   usersId: selectedTargetName,
    // };
    // getTargetGaugeDataAPICall(TargetGraphData);
  }, []);

  useEffect(() => {
    if (
      getAnalyticsNameData &&
      getAnalyticsNameData?.names &&
      getAnalyticsNameData?.names?.length > 0
    ) {
      setSelectedName(getAnalyticsNameData?.names[0]?.id);
      setSelectedTargetName(getAnalyticsNameData?.names[0]?.id);
      setSelectedSalesPerson(getAnalyticsNameData?.names[0]?.id);
    }
  }, [getAnalyticsNameData]);

  useEffect(() => {
    if (selectedName) {
      const barGraphData = {
        fromDate: selectedFromDate,
        toDate: selectedToDate1,
        id: selectedName,
      };
      getAnalyticsBarGraphDataAPICall(barGraphData);
    }
  }, [selectedName]);

  useEffect(() => {
    if (selectedSalesPerson) {
      const pieGraphData = {
        fromDate: selectedPieChartStartDate,
        toDate: selectedPieChartEndDate,
        id: selectedSalesPerson,
      };
      getTemperaturePieChartDataApiCall(pieGraphData);
      getEmailGraphAPICall(pieGraphData);
    }
  }, [selectedSalesPerson]);

  const applyDateFilter = () => {
    const searchData = {
      fromDate: selectedDate,
      toDate: selectedToDate,
    };
    getAnalyticsGraphDataAPICall(searchData);
  };

  const applyBarDateFilter = () => {
    const barGraphData = {
      fromDate: selectedFromDate,
      toDate: selectedToDate1,
      id: selectedName,
    };
    getAnalyticsBarGraphDataAPICall(barGraphData);
  };

  const applyLeadsDataDateFilter = () => {
    const pieGraphData = {
      fromDate: selectedPieChartStartDate,
      toDate: selectedPieChartEndDate,
      id: selectedSalesPerson,
    };
    getTemperaturePieChartDataApiCall(pieGraphData);
    getEmailGraphAPICall(pieGraphData);
  };

  const ApplyTargetFilter = () => {
    const TargetGraphData = {
      startDate: selectedFromDate1,
      endDate: selectedToDate2,
    };
    getTargetGaugeDataAPICall(TargetGraphData);
  };

  const useStyles = makeStyles((theme) => {
    return createStyles({
      datepicker: {
        margin: "0px 0 0px 10px !important",
        width: "170px",
        borderRadius: "20px",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#add8e6",
        margin: "5px",
        width: "99%",
        height: "55px",
      },
      graphcontainer: {
        display: "flex",
        alignItems: "center",
      },
      gridbox: {
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
        margin: "5px !important",
        borderRadius: "6px",
      },
      heading: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "10px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "18px !important",
      },
      nodata: {
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "16px !important",
        color: "grey",
      },
      heading1: {
        display: "flex",
        justifyContent: "center",
        MarginTop: "10px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "18px !important",
      },
      subheading: {
        margin: "0 0 0 20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      datefilter: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "32px",
        textTransform: "capitalize",
        marginLeft: "20px",
        marginTop: "2px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "12px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "10%",
      },
      dottedDivider: {
        width: "100%",
        textAlign: "center",
        borderTop: "2px dotted" + theme.palette.divider,
        margin: "20px 0",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();

  const today = selectedDate ? new Date(selectedDate) : new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDate = dayjs(formattedDate);

  const today1 = selectedToDate ? new Date(selectedToDate) : new Date();
  const formattedDate1 = today1.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDate = dayjs(formattedDate1);

  const onhandleChangeForFromDate = (e: any) => {
    setSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const onhandleChangeForToDate = (e: any) => {
    setToSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const currentDate = selectedFromDate
    ? new Date(selectedFromDate)
    : new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDateSelected = dayjs(formattedCurrentDate);

  const toDateToday = selectedToDate1 ? new Date(selectedToDate1) : new Date();
  const formattedToDateToday = toDateToday.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDateSelected = dayjs(formattedToDateToday);

  const pieChartStartDate = dayjs(formattedCurrentDate);
  const pieChartEndDate = dayjs(formattedToDateToday);

  const handleFromDateChange = (e: any) => {
    setSelectedFromDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleToDateChange = (e: any) => {
    setSelectedToDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handlePieChartStartDate = (e: any) => {
    setSelectedPieChartStartDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handlePieChartEndDate = (e: any) => {
    setSelectedPieChartEndDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const currentDate1 = selectedFromDate1
    ? new Date(selectedFromDate1)
    : new Date();
  const formattedCurrentDate1 = currentDate1.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDateSelected1 = dayjs(formattedCurrentDate1);

  const toDateToday1 = selectedToDate2 ? new Date(selectedToDate2) : new Date();
  const formattedToDateToday1 = toDateToday1.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDateSelected1 = dayjs(formattedToDateToday1);

  const handleFromDateChange1 = (e: any) => {
    setSelectedFromDate1(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleToDateChange1 = (e: any) => {
    setSelectedToDate2(moment(e.$d).format("YYYY-MM-DD"));
  };

  const targetName = getAnalyticsNameData?.names?.find(
    (name: any) => name.id === selectedTargetName
  )?.first_name;
  const targetValue = getTargetGaugeData?.data?.target;

  const getGridSize = () => {
    const length = getTargetGaugeData?.dealSizeTargetData?.length;
    if (length === 1) {
      return 12;
    } else if (length === 2) {
      return 6;
    } else {
      return 4;
    }
  };

  return (
    <Paper sx={{ margin: "10px" }}>
      {isGettingAnalyticsData && <div className="overlay" />}
      {isGettingAnalyticsData && (
        <CircularProgress color="primary" className={classes.progressBar} />
      )}
      {userRole !== "sales" && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{ marginBottom: "10px" }}
                className={classes.datepickercontainer}
              >
                <Typography className={classes.subheading}>From: </Typography>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <Box className={classes.datepicker}>
                        <DatePicker
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "20px",
                              border: "none",
                              height: "30px",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#ffffff",
                              borderRadius: "20px",
                              marginBottom: "5px",
                              height: "30px",
                            },
                          }}
                          // sx={{borderRadius:'20px'}}
                          // disabled={true}
                          value={fromDateSelected1}
                          onChange={handleFromDateChange1}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <Typography className={classes.subheading}>To: </Typography>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <Box className={classes.datepicker}>
                        <DatePicker
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "20px",
                              border: "none",
                              height: "30px",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#ffffff",
                              borderRadius: "20px",
                              marginBottom: "5px",
                              height: "30px",
                            },
                          }}
                          // disabled={true}
                          value={toDateSelected1}
                          onChange={handleToDateChange1}
                          minDate={fromDateSelected1}
                        />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <button
                  className={classes.datefilter}
                  onClick={ApplyTargetFilter}
                >
                  <CheckCircleOutlineOutlinedIcon sx={{ height: "50%" }} />
                  Show Result
                </button>
              </div>
              {isGettingTargetChartData ? (
                // Show Circular Progress when data is being fetched
                <div
                style={{
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
              ) : getTargetGaugeData?.dealSizeTargetData?.length ? (
                <Grid container spacing={0.5}>
                  {getTargetGaugeData?.dealSizeTargetData?.map(
                    (chartData: any, index: any) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={getGridSize()}
                        key={index}
                      >
                        <Box className={classes.gridbox}>
                          <Typography
                            sx={{
                              paddingTop: "5px",
                              marginBottom: "10px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className={classes.subheading}
                          >
                            Target for {chartData.name}{" "}
                            {chartData.target ? `is $${chartData.target}` : ""}
                          </Typography>
                          {chartData.target ? (
                            <TargetChart
                              chartId={`targetchart-${index}`}
                              data={chartData}
                            />
                          ) : (
                            <div
                              style={{
                                height: "300px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span className={classes.nodata}>
                                Target is not set
                              </span>
                            </div>
                          )}
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              ) : (
                <>
                  <Typography className={classes.heading}>Target Set</Typography>
                  <div
                    style={{
                      height: "300px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className={classes.nodata}>No data available </span>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
          <div className={classes.datepickercontainer}>
            <Typography className={classes.subheading}>From: </Typography>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <Box className={classes.datepicker}>
                    <DatePicker
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "20px",
                          border: "none",
                          height: "30px",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#ffffff",
                          borderRadius: "20px",
                          marginBottom: "5px",
                          height: "30px",
                        },
                      }}
                      // sx={{borderRadius:'20px'}}
                      value={fromDate}
                      onChange={onhandleChangeForFromDate}
                    />
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <Typography className={classes.subheading}>To: </Typography>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <div className={classes.datepicker}>
                    <DatePicker
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "20px",
                          border: "none",
                          height: "30px",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#ffffff",
                          borderRadius: "20px",
                          marginBottom: "5px",
                          height: "30px",
                        },
                      }}
                      value={toDate}
                      onChange={onhandleChangeForToDate}
                      //   disableFuture={true}
                      minDate={fromDate}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <button className={classes.datefilter} onClick={applyDateFilter}>
              <CheckCircleOutlineOutlinedIcon sx={{ height: "50%" }} />
              Show Result
            </button>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.gridbox}>
                <Typography className={classes.heading}>Email Sent </Typography>
                {/* <AnalyticsGraph /> */}
                {getAnalyticsGraphData &&
                  getAnalyticsGraphData?.length > 0 &&
                  getAnalyticsGraphData.some(
                    (data: any) => data.totalemailCount > 0
                  ) ? (
                  <AnalyticsGraph />
                ) : (
                  <div
                    style={{
                      height: "318px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className={classes.nodata}>No data available </span>
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: "0 !important" }}>
              <Box className={classes.gridbox}>
                <Typography className={classes.heading}>Call-Done </Typography>
                {/* <AnalyticsStatusGraph /> */}
                {getAnalyticsGraphData &&
                  getAnalyticsGraphData?.length > 0 &&
                  getAnalyticsGraphData.some(
                    (data: any) => data.totalcallCount > 0
                  ) ? (
                  <AnalyticsStatusGraph />
                ) : (
                  <div
                    style={{
                      height: "318px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className={classes.nodata}>No data available </span>
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{ marginBottom: "10px" }}
            className={classes.datepickercontainer}
          >
            <Typography className={classes.subheading}>From: </Typography>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <Box className={classes.datepicker}>
                    <DatePicker
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "20px",
                          border: "none",
                          height: "30px",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#ffffff",
                          borderRadius: "20px",
                          marginBottom: "5px",
                          height: "30px",
                        },
                      }}
                      // sx={{borderRadius:'20px'}}
                      value={fromDateSelected}
                      onChange={handleFromDateChange}
                    />
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <Typography className={classes.subheading}>To: </Typography>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <Box className={classes.datepicker}>
                    <DatePicker
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "20px",
                          border: "none",
                          height: "30px",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#ffffff",
                          borderRadius: "20px",
                          marginBottom: "5px",
                          height: "30px",
                        },
                      }}
                      value={toDateSelected}
                      onChange={handleToDateChange}
                      minDate={fromDateSelected}
                    />
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <button className={classes.datefilter} onClick={applyBarDateFilter}>
              <CheckCircleOutlineOutlinedIcon sx={{ height: "50%" }} />
              Show Result
            </button>
            {/* {getAnalyticsNameData && getAnalyticsNameData?.length > 0 && ( */}
            <Select
              value={selectedName}
              onChange={(e) => setSelectedName(e.target.value)}
              displayEmpty
              sx={{
                marginLeft: "auto",
                marginRight: "10px",
                borderRadius: "20px",
                height: "30px",
                backgroundColor: "#fff",
                width: "150px",
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                  },
                },
              }}
              inputProps={{ "aria-label": "Select owner" }}
            >
              {getAnalyticsNameData?.names?.map((data: any) => (
                <MenuItem key={data.first_name} value={data.id}>
                  {`${data.first_name || ""} ${data.last_name || ""}`.trim()}
                </MenuItem>
              ))}
            </Select>
            {/* )} */}
          </div>
          <Box className={classes.gridbox}>
            <Typography
              sx={{ paddingTop: "10px" }}
              className={classes.heading1}
            >
              Email sent statistics
            </Typography>
            {/* <AnalyticsBarGraph /> */}
            {getAnalyticsBarGraphData?.analytics &&
              getAnalyticsBarGraphData?.analytics?.length > 0 ? (
              <AnalyticsBarGraph />
            ) : (
              <div
                style={{
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span className={classes.nodata}>No data available </span>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item xs={12}> */}
      <div
        style={{ marginBottom: "10px" }}
        className={classes.datepickercontainer}
      >
        <Typography className={classes.subheading}>From: </Typography>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box className={classes.datepicker}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                      border: "none",
                      height: "30px",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#ffffff",
                      borderRadius: "20px",
                      marginBottom: "5px",
                      height: "30px",
                    },
                  }}
                  // sx={{borderRadius:'20px'}}
                  value={pieChartStartDate}
                  onChange={handlePieChartStartDate}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <Typography className={classes.subheading}>To: </Typography>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box className={classes.datepicker}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                      border: "none",
                      height: "30px",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#ffffff",
                      borderRadius: "20px",
                      marginBottom: "5px",
                      height: "30px",
                    },
                  }}
                  value={pieChartEndDate}
                  onChange={handlePieChartEndDate}
                  minDate={pieChartStartDate}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <button
          className={classes.datefilter}
          onClick={applyLeadsDataDateFilter}
        >
          <CheckCircleOutlineOutlinedIcon sx={{ height: "50%" }} />
          Show Result
        </button>
        {/* {getAnalyticsNameData && getAnalyticsNameData?.length > 0 && ( */}
        <Select
          value={selectedSalesPerson}
          onChange={(e) => setSelectedSalesPerson(e.target.value)}
          displayEmpty
          sx={{
            marginLeft: "auto",
            marginRight: "10px",
            borderRadius: "20px",
            height: "30px",
            backgroundColor: "#fff",
            width: "150px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "300px",
              },
            },
          }}
          inputProps={{ "aria-label": "Select owner" }}
        >
          {getAnalyticsNameData?.names?.map((data: any) => (
            <MenuItem key={data.id} value={data.id}>
              {`${data.first_name || ""} ${data.last_name || ""}`.trim()}
            </MenuItem>
          ))}
        </Select>
        {/* )} */}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box className={classes.gridbox}>
            <Typography
              sx={{ paddingTop: "10px" }}
              className={classes.heading1}
            >
              Temperature
            </Typography>
            {isGettingTemperaturePieChartData ? (
              <div
                style={{
                  height: "318px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : getTemperaturePieChartData?.data?.length ? (
              <TemperatureChart
                pieChartData={getTemperaturePieChartData?.data}
              />
            ) : (
              <div
                style={{
                  height: "318px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span className={classes.nodata}>No data available</span>
              </div>
            )}
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: "0 !important" }}>
          <Box className={classes.gridbox}>
            <Typography className={classes.heading}>Track Email </Typography>
            {isGettingEmailGraphData ? (
              <div
                style={{
                  height: "318px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : getEmailGraphData?.length ? (
              <TrackEmailChart />
            ) : (
              <div
                style={{
                  height: "318px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span className={classes.nodata}>No data available</span>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* </Grid>
      </Grid> */}
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAnalyticsGraphData:
      analyticsDataEntity.getAnalyticsGraph(state).getAnalyticsGraphData,
    getAnalyticsBarGraphData:
      analyticsDataEntity.getAnalyticsGraph(state).getAnalyticsBarGraphData,
    getAnalyticsNameData:
      analyticsDataEntity.getAnalyticsGraph(state).getAnalyticsNameData,
    getTargetGaugeData:
      analyticsDataEntity.getAnalyticsGraph(state).getTargetGaugeData,
    isGettingAnalyticsData:
      analyticsDataUI.getAnalytics(state).isGettingAnalyticsData,
    isGettingTemperaturePieChartData:
      analyticsDataUI.getAnalytics(state).isGettingTemperaturePieChartData,
    isGettingTargetChartData:
      analyticsDataUI.getAnalytics(state).isGettingTargetChartData,
    getTemperaturePieChartData:
      analyticsDataEntity.getAnalyticsGraph(state).getTemperatureData,
    getEmailGraphData: dashboardEntity.getDashboard(state).getEmailGraphData,
    isGettingEmailGraphData:
      dashboardUI.getDashboard(state).isGettingEmailGraphData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAnalyticsGraphDataAPICall: (data: any) =>
      dispatch(getAnalyticsGraphDataAPICall.request(data)),
    getAnalyticsBarGraphDataAPICall: (data: any) =>
      dispatch(getAnalyticsBarGraphDataAPICall.request(data)),
    getAnalyticsNameDataAPICall: (data: any) =>
      dispatch(getAnalyticsNameDataAPICall.request(data)),
    getTargetGaugeDataAPICall: (data: any) =>
      dispatch(getTargetGaugeDataAPICall.request(data)),
    getTemperaturePieChartDataApiCall: (data: any) =>
      dispatch(getTemperaturePieChartDataAPICall.request(data)),
    getEmailGraphAPICall: (data: any) =>
      dispatch(getEmailGraphAPICall.request(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
