import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { connect } from "react-redux";
import {
  addCampaignDataAPICall,
  editCampaignDataAPICall,
  getAddUserDataAPICall,
  getManagerDataAPICall,
} from "../../actions";
import { campaignDataEntity, campaignDataUI, dashboardEntity } from "../../reducers";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiFormLabel-asterisk": {
    color: "red"
  }
}));

const EditCampaign = (props: any) => {
  const {
    addCampaignDataAPICall,
    editCampaignDataAPICall,
    handleEditCampaignDialogClose,
    getCampaignLeadsData,
    gettingCampaignLeadsData,
  } = props;

  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedToDate1, setSelectedToDate] = useState(
    moment().add(10, "days").format("YYYY-MM-DD")
  );

  useEffect(() => {
    setSelectedFromDate(moment(getCampaignLeadsData?.data?.start_date).format("YYYY-MM-DD"))
    setSelectedToDate(moment(getCampaignLeadsData?.data?.end_date).format("YYYY-MM-DD"))
  }, [getCampaignLeadsData])

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      disabled: {
        cursor: "not-allowed",
      },
      datepicker1: {
        width: "425px",
        borderRadius: "20px",
      },
      datepicker2: {
        width: "418px",
        borderRadius: "20px",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "#ffffff",
        },
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#add8e6",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 4% !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "0px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "10% !important",
        wordBreak: "break-word",
      },
      progressBar: {
        position: "absolute",
        top: "46%",
        left: "48%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  const classes = useStyles();

  const currentDate = selectedFromDate
    ? new Date(selectedFromDate)
    : new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDateSelected = dayjs(formattedCurrentDate);

  const toDateToday = selectedToDate1 ? new Date(selectedToDate1) : new Date();
  const formattedToDateToday = toDateToday.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDateSelected = dayjs(formattedToDateToday);

  const handleFromDateChange = (e: any) => {
    setSelectedFromDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleToDateChange = (e: any) => {
    setSelectedToDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const formik = useFormik({
    initialValues: {
      name: getCampaignLeadsData?.data?.name || "",
      type: getCampaignLeadsData?.data?.type || "",
      status: getCampaignLeadsData?.data?.status || "",
      expectedRevenue: getCampaignLeadsData?.data?.expected_revenue || "",
      budgetedCost: getCampaignLeadsData?.data?.budgeted_cost || "",
      actualCost: getCampaignLeadsData?.data?.actual_cost || "",
      expectedResponse: getCampaignLeadsData?.data?.expected_response || "",
      numbersSent: getCampaignLeadsData?.data?.numbers_sent || "",
      description: getCampaignLeadsData?.data?.description || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      type: Yup.string().required("Type is required"),
      status: Yup.string().required("Status is required"),
      expectedRevenue: Yup.number()
        .typeError("Expected Revenue must be a number")
        .min(0, "Expected Revenue must be a positive number")
        .required("Expected Revenue is required"),
      budgetedCost: Yup.number()
        .typeError("Budgeted Cost must be a number")
        .min(0, "Budgeted Cost must be a positive number")
        .required("Budgeted Cost is required"),
      actualCost: Yup.number()
        .typeError("Actual Cost must be a number")
        .min(0, "Actual Cost must be a positive number")
        .required("Actual Cost is required"),
      expectedResponse: Yup.number()
        .typeError("Expected Response must be a number")
        .min(0, "Expected Response must be a positive number"),
      numbersSent: Yup.number()
        .typeError("Numbers Sent must be a number")
        .min(0, "Numbers Sent must be a positive number"),
    }),
    onSubmit: (values, event: any) => { },
  });

  const handleSubmit = () => {
    let Userdata = {
      id: getCampaignLeadsData.data.id,
      start_date: selectedFromDate,
      end_date: selectedToDate1,
      name: formik.values?.name,
      type: formik.values?.type,
      status: formik.values?.status,
      expected_revenue: formik.values?.expectedRevenue,
      budgeted_cost: formik.values?.budgetedCost,
      actual_cost: formik.values?.actualCost,
      expected_response: formik.values?.expectedResponse,
      numbers_sent: formik.values?.numbersSent,
      description: formik.values?.description,
    };
    if (Userdata) {
      editCampaignDataAPICall(Userdata);
      handleEditCampaignDialogClose();
    }
  };

  const getErrorText = (error: any): string | undefined => {
    return typeof error === "string" ? error : undefined;
  };

  return (
    <>
      {gettingCampaignLeadsData && (
        <CircularProgress color="primary" className={classes.progressBar} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              {/* <Box className={classes.datepicker1}> */}
              <Box sx={{ overflow: 'hidden', width: '50vw' }}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": { height: "20px" },
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }}
                  // sx={{borderRadius:'20px'}}
                  value={fromDateSelected}
                  label="Start Date"
                  onChange={handleFromDateChange}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <Box sx={{ overflow: 'hidden', width: '50vw' }}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiInputBase-input": { height: "20px" },
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }}
                  label="End Date"
                  value={toDateSelected}
                  onChange={handleToDateChange}
                  minDate={fromDateSelected}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
      </Grid>
      {/* </div> */}
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.touched.name ? getErrorText(formik.errors.name) : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                select
                id="type"
                name="type"
                label="Type"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                      },
                    },
                  },
                }}
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={
                  formik.touched.type ? getErrorText(formik.errors.type) : ""
                }
              >
                <MenuItem value="Advertisement">Advertisement</MenuItem>
                <MenuItem value="Banner Ads">Banner Ads</MenuItem>
                <MenuItem value="Conference">Conference</MenuItem>
                <MenuItem value="Direct Mail">Direct Mail</MenuItem>
                <MenuItem value="Email">Email</MenuItem>
                <MenuItem value="Partners">Partners</MenuItem>
                <MenuItem value="Public Relations">Public Relations</MenuItem>
                <MenuItem value="Referral Program">Referral Program</MenuItem>
                <MenuItem value="Telemarketing">Telemarketing</MenuItem>
                <MenuItem value="Trade Show">Trade Show</MenuItem>
                <MenuItem value="Webinar">Webinar</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </StyledTextField>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="expectedRevenue"
                name="expectedRevenue"
                label="Expected Revenue"
                sx={{ marginTop: "0", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.expectedRevenue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.expectedRevenue &&
                  Boolean(formik.errors.expectedRevenue)
                }
                helperText={
                  formik.touched.expectedRevenue
                    ? getErrorText(formik.errors.expectedRevenue)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="budgetedCost"
                name="budgetedCost"
                label="Budgeted Cost"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.budgetedCost}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.budgetedCost &&
                  Boolean(formik.errors.budgetedCost)
                }
                helperText={
                  formik.touched.budgetedCost
                    ? getErrorText(formik.errors.budgetedCost)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="actualCost"
                name="actualCost"
                label="Actual Cost"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.actualCost}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.actualCost && Boolean(formik.errors.actualCost)
                }
                helperText={
                  formik.touched.actualCost
                    ? getErrorText(formik.errors.actualCost)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                select
                id="status"
                name="status"
                label="Status"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                      },
                    },
                  },
                }}
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={
                  formik.touched.status ? getErrorText(formik.errors.status) : ""
                }
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Complete">Complete</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
                <MenuItem value="Planning">Planning</MenuItem>
              </StyledTextField>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="expectedResponse"
                name="expectedResponse"
                label="Expected Response"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.expectedResponse}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.expectedResponse &&
                  Boolean(formik.errors.expectedResponse)
                }
                helperText={
                  formik.touched.expectedResponse
                    ? getErrorText(formik.errors.expectedResponse)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="numbersSent"
                name="numbersSent"
                label="Numbers Sent"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.numbersSent}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.numbersSent &&
                  Boolean(formik.errors.numbersSent)
                }
                helperText={
                  formik.touched.numbersSent
                    ? getErrorText(formik.errors.numbersSent)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: "5px" }}>
            <TextField
              fullWidth
              multiline
              id="description"
              name="description"
              label="Description"
              required={false}
              sx={{ marginTop: "0px", marginBottom: "15px" }}
              InputProps={{ sx: { padding: "0", borderRadius: 4 } }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description
                  ? getErrorText(formik.errors.description)
                  : ""
              }
            />
          </div>
        </Grid>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            className={classes.cancelbtn}
            // variant='contained'
            color="primary"
            onClick={() => handleEditCampaignDialogClose()}
          >
            Cancel
          </Button>
          <span style={{ cursor: "not-allowed" }}>
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              type="submit"
              // disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </span>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getCampaignLeadsData:
      campaignDataEntity.getCampaign(state).getCampaignLeadsData,
    gettingCampaignLeadsData:
      campaignDataUI.getCampaign(state).gettingCampaignLeadsData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    editCampaignDataAPICall: (data: any) =>
      dispatch(editCampaignDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaign);
