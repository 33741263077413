import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { searchBar } from "../Contacts/style";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import {
  getEmailDetailsDataAPICall,
  getEmailTrackDataAPICall,
  getLeadsStatusDataAPICall,
} from "../../actions";
import {
  dashboardEntity,
  dashboardUI,
  leadsDataEntity,
} from "../../reducers";
import moment from "moment";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import TrackEmailDetails from "./TrackEmailDetails";
import { filter } from "../Leads/constant";
import TrackEmailDetailsPagination from "./TrackEmailDetailsPagination";

interface TypeOption {
  label: string;
  value: string;
}

const EmailStatus = (props: any) => {
  const {
    isGettingEmailTrackData,
    getEmailTrackDataAPICall,
    getEmailTrackData,
    getEmailDetailsDataAPICall,
  } = props;

  const [isContactsDetailsDialogOpen, setIsEmailDetailsDialogOpen] =
    useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>();
  const [limit, setLimit] = useState(100);
  const [pageChange, setPageChange] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelctedDate] = useState(
    moment(new Date()).subtract(2, "days").format("YYYY-MM-DD")
  );
  const [selectedToDate, setToSelctedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [bounceType, setBounceType] = useState<TypeOption[]>([]);
  const [selectedBounceType, setSelectedBounceType] = useState<
    { label: string; value: string } | string
  >({
    label: "All",
    value: "All",
  });
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (filter) {
      const type = filter?.map((type: { label: string; value: string }) => ({
        label: type.label,
        value: type.value,
      }));
      setBounceType(type);
      setDataLoaded(true);

      const All = type?.find((filter: any) => filter.value === "All");
      if (All) {
        setSelectedBounceType(All.value);
      }
    }
  }, [filter]);

  useEffect(() => {
    if (dataLoaded) {
      const emailData = {
        pageChange: pageChange,
        limit: limit,
        searchString: searchTerm,
        startDate: selectedDate,
        endDate: selectedToDate,
        type: selectedBounceType,
      };
      getEmailTrackDataAPICall(emailData);
    }
  }, [limit, pageChange, selectedBounceType, selectedDate, selectedToDate]);

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      datepicker: {
        margin: "0px 0 0px 10px !important",
        width: "170px",
        borderRadius: "20px",
      },
      headerWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
      },
      title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#add8e6",
        margin: "5px",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      titletxt: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "18px!important",
        fontWeight: 500,
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      search: {
        color: "#666666",
        cursor: "pointer",
        position: "absolute",
        top: 5,
        left: 5,
      },
      upload: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        border: "1px solid rgba(20, 56, 113, 1)",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        width: "20%",
        whiteSpace: "nowrap",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "10px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "100% !important",
        wordBreak: "break-word",
      },
      progressBar: {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });
  const classes = useStyles();

  const handleRowClick = (messageId: string) => {
    setIsEmailDetailsDialogOpen(true);
    setMessageId(messageId);
    const data = {
      id: messageId,
    };
    getEmailDetailsDataAPICall(data);
  };

  const handleEmailDetailsDialogClose = () => {
    setIsEmailDetailsDialogOpen(false);
  };

  const handleSearch = (event: any) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm === "") {
      const emailData = {
        pageChange: pageChange,
        limit: limit,
        searchString: newSearchTerm,
        startDate: selectedDate,
        endDate: selectedToDate,
        type: selectedBounceType,
      };
      getEmailTrackDataAPICall(emailData);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      const emailData = {
        pageChange: pageChange,
        limit: limit,
        searchString: searchTerm,
        startDate: "",
        endDate: "",
        type: selectedBounceType,
      };
      getEmailTrackDataAPICall(emailData);
    }
  };

  const handleLimitChange = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setPageChange(1);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage);
  };

  const today = selectedDate ? new Date(selectedDate) : new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const fromDate = dayjs(formattedDate);

  const today1 = selectedToDate ? new Date(selectedToDate) : new Date();
  const formattedDate1 = today1.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const toDate = dayjs(formattedDate1);
  const onhandleChangeForFromDate = (e: any) => {
    setSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
  };
  const onhandleChangeForToDate = (e: any) => {
    // setToSelctedDate(moment(new Date(e?.$d)).toISOString())
    setToSelctedDate(moment(e.$d).format("YYYY-MM-DD"));
  };

  const handleTypeChange = (e: MultiSelectChangeEvent) => {
    setSelectedBounceType(e.target.value);
  };

  return (
    <Paper sx={{ margin: "10px", minHeight: "300px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
        }}
      >
        <Box sx={{ marginLeft: "16px" }}>
          <TextField
            id="search"
            name="search"
            type="text"
            placeholder="Search Email or Subject"
            InputLabelProps={{ style: { color: "#666666" } }}
            size="small"
            InputProps={{
              style: { ...{ padding: "0" } },
              type: "search",
              startAdornment: (
                <Tooltip title="Search" placement="top">
                  <span>
                    <SearchTwoToneIcon className={classes.search} />
                  </span>
                </Tooltip>
              ),
            }}
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyPress}
            sx={{ ...searchBar }}
          />
        </Box>

        <Typography
          className={classes.headingtxt}
          style={{
            position: "absolute",
            left: "48%",
            transform: "translateX(-50%)",
          }}
        >
          Email Status
        </Typography>

        <div
          style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
        >
          <div className="card flex justify-content-center">
            <Dropdown
              value={selectedBounceType}
              onChange={handleTypeChange}
              options={bounceType}
              optionLabel="label"
              optionValue="value"
              placeholder="Type"
              className="w-full md:w-14rem custom-class"
              style={{
                width: "150px",
                height: "35px",
                color: "#000000",
                borderRadius: "20px",
                fontSize: "10px",
                margin: "0 0 0 10px",
              }}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ padding: "0" }} components={["DatePicker"]}>
              <Box className={classes.datepicker}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiOutlinedInput-input": {
                      paddingRight: "10px !important",
                      height: "18px",
                    },
                    "& .MuiFormLabel-asterisk": {
                      color: "red",
                    },
                  }}
                  label="From Date"
                  slotProps={{
                    textField: {
                      InputLabelProps: {
                        style: { top: "-8px" },
                      },
                    },
                  }}
                  value={fromDate}
                  onChange={onhandleChangeForFromDate}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ padding: "0" }} components={["DatePicker"]}>
              <Box className={classes.datepicker}>
                <DatePicker
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "20px",
                    },
                    "& .MuiOutlinedInput-input": {
                      paddingRight: "10px !important",
                      height: "18px",
                    },
                    "& .MuiFormLabel-asterisk": {
                      color: "red",
                    },
                  }}
                  label="To Date"
                  slotProps={{
                    textField: {
                      InputLabelProps: {
                        style: { top: "-8px" },
                      },
                    },
                  }}
                  value={toDate}
                  onChange={onhandleChangeForToDate}
                  minDate={fromDate}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div
        style={{
          margin: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1",
          minHeight: "360px",
        }}
      >
        <TableContainer sx={{ marginBottom: "10px", width: "100%" }}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
            >
              <TableRow>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Receiver</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Subject</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Status</span>
                </TableCell>
                <TableCell sx={{ padding: "6px 16px" }}>
                  <span className="mail-info">Date</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isGettingEmailTrackData ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              ) : getEmailTrackData?.data?.emails?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={12} className="tablebodycell">
                    <div
                      style={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data Available
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                getEmailTrackData?.data?.emails?.map(
                  (email: any, index: any) => (
                    <TableRow
                      key={index}
                      sx={{ cursor: "pointer", background: "#ffffff" }}
                      onClick={() => handleRowClick(email?.MessageID)}
                    >
                      <TableCell
                        className="tablebodycell"
                        sx={{ padding: "6px 16px !important" }}
                      >
                        <Tooltip title={email?.To}>
                          {email?.To
                            ? email?.To.length > 30
                              ? email?.To.slice(0, 30) + "..."
                              : email?.To
                            : "NA"}
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className="tablebodycell"
                        sx={{ padding: "6px 16px !important" }}
                      >
                        <Tooltip title={email?.Subject}>
                          {email?.Subject
                            ? email?.Subject.length > 50
                              ? email?.Subject.slice(0, 50) + "..."
                              : email?.Subject
                            : "NA"}
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className="tablebodycell"
                        sx={{ padding: "6px 16px !important" }}
                      >
                        {email?.recent_status ? email?.recent_status : "NA"}
                      </TableCell>
                      <TableCell
                        className="tablebodycell"
                        sx={{ padding: "6px 16px !important" }}
                      >
                        {email?.SubmittedAt
                          ? moment(email?.SubmittedAt).format(
                              "MM/DD/YYYY hh:mm:ss A"
                            )
                          : "NA"}
                      </TableCell>
                    </TableRow>
                  )
                )
              )}
            </TableBody>
          </Table>
          <TrackEmailDetailsPagination
            emailTableData={getEmailTrackData?.data}
            handleChangePage={handleChangePage}
            handleLimitChange={handleLimitChange}
            pageChange={pageChange}
            limit={limit}
          />
        </TableContainer>
      </div>

      <Dialog
        open={isContactsDetailsDialogOpen}
        onClose={handleEmailDetailsDialogClose}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 24px",
          }}
        >
          Email Status Details
          <IconButton
            aria-label="close"
            onClick={handleEmailDetailsDialogClose}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="campaign-divider" />
        <DialogContent sx={{ padding: "10px 15px !important" }}>
          <TrackEmailDetails />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getLeadsStatusData: leadsDataEntity.getLeads(state).getLeadsStatusData,
    isGettingEmailTrackData:
      dashboardUI.getDashboard(state).isGettingEmailTrackData,
    getEmailTrackData: dashboardEntity.getDashboard(state).getEmailTrackData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getLeadsStatusDataAPICall: (data: any) =>
      dispatch(getLeadsStatusDataAPICall.request(data)),
    getEmailTrackDataAPICall: (data: any) =>
      dispatch(getEmailTrackDataAPICall.request(data)),
    getEmailDetailsDataAPICall: (data: any) =>
      dispatch(getEmailDetailsDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailStatus);
