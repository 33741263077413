import React, { useEffect, useState } from "react";
import { Calendar as BigCalendar, momentLocalizer, Views,
  Event as BigCalendarEvent, stringOrDate } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { getEventCalendarDataAPICall } from "../../actions";
import { dashboardEntity } from "../../reducers";
import "./Calendar.css";
import { Popover, Tooltip, Typography } from "@mui/material";

const localizer = momentLocalizer(moment);

interface EventCalendarData {
  date: string;
  entries: {
    time: string;
    title: string;
  }[];
}

// interface MeetingCalendarProps {
//   getEventCalendarDataAPICall: (data: { month: number; year: number }) => void;
//   getEventCalendarData?: { data: EventCalendarData[] };
// }

interface CustomEvent {
  title: string;
  start: Date;
  end: Date;
}

interface PopoverPosition {
  top: number;
  left: number;
}

function convertTextToHTML(html: string) {
  const div = document.createElement("div");
  div.innerHTML = html;
  let text = div.textContent || div.innerText || "";

  text = text
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");

  text = text.replace(/\s+/g, " ").trim();

  return <div>{text}</div>;
}

const MeetingCalendar = (props: any) => {
    const { 
        getEventCalendarDataAPICall,
        getEventCalendarData,
    } = props;
    
  const [viewDate, setViewDate] = useState(new Date());
  const [currentView, setCurrentView] = useState<string>(Views.MONTH);
  const [selectedEvent, setSelectedEvent] = useState<CustomEvent | null>(null);
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<CustomEvent | null>(null);

  useEffect(() => {
    const currentMonth = viewDate.getMonth() + 1;
    const currentYear = viewDate.getFullYear();
    const calendarData = { month: currentMonth, year: currentYear };
    getEventCalendarDataAPICall(calendarData);
  }, [viewDate, getEventCalendarDataAPICall]);

  const formatTime = (time: string): string => {
    return moment(time, "HH:mm:ss").format("h:mm a");
  };

  const events: CustomEvent[] = (getEventCalendarData?.data || []).flatMap((dayData:any) =>
    dayData.entries.map((entry:any) => ({
      start: new Date(`${dayData.date}T${entry.time}`),
      end: new Date(`${dayData.date}T${entry.time}`),
      title: entry.title,
    }))
  );

  const handleNavigate = (date: any) => {
    setViewDate(new Date(date));
  };

  const handleViewChange = (view: string) => {
    setCurrentView(view);
  };

  const handleEventSelect = (event: CustomEvent, e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedEvent(event);
    setAnchorEl(event);
    setAnchorPosition({ top: e.clientY, left: e.clientX });
  };

  const handleClosePopover = () => {
    setSelectedEvent(null);
    setAnchorEl(null);
  };

  const CustomEventWrapper: React.FC<{ event: CustomEvent }> = ({ event }) => {
  //   <div className="event" onClick={(e) => handleEventSelect(event, e)}>
  //     {convertTextToHTML(event.title)}
  //   </div>
  // );
  const plainTextTitle = convertTextToHTML(event.title);

  return (
    <Tooltip title={plainTextTitle} arrow>
      <div className="event">
        {/* Display the plain text as a preview in the month view */}
        {plainTextTitle}
      </div>
    </Tooltip>  
  );
};
  
  const eventPropGetter = (
    event: CustomEvent,
    start: Date,
    end: Date,
    isSelected: boolean
  ) => {
    if (currentView === Views.DAY) {
      const eventIndex = events.filter(e => e.start.getTime() === start.getTime()).indexOf(event) + 1;
      const className = `rbc-event-${eventIndex}`;
      return {
        className,
        style: {
          border: '1px solid',
        }
      };
    }
    if (currentView === Views.MONTH) {
      return {
        className: "custom-month-event",
      };
    }
    return {};
  };

  const dayStart = new Date();
  dayStart.setHours(9, 0, 0, 0); 

  return (
    <>
    <BigCalendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      onNavigate={handleNavigate}
      onView={handleViewChange}
      components={{
        event: CustomEventWrapper,
        // agenda: {
        //   event: EventComponent,
        // },
      }}
      eventPropGetter={eventPropGetter}
      scrollToTime={dayStart}
      tooltipAccessor={() => ""}  
      />
     <Popover
        open={Boolean(selectedEvent)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography 
        sx={{ p: 2,maxWidth:'500px',maxHeight:'400px' }}>
          {selectedEvent && (
            <>
              <div>
                <span className="event1">{moment(selectedEvent.start).format("h:mm a")}:</span> <span className="event2">{convertTextToHTML(selectedEvent.title)}</span>
              </div>
            </>
          )}
        </Typography>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getEventCalendarData: dashboardEntity.getDashboard(state).eventCalendarData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventCalendarDataAPICall: (data: { month: number; year: number }) =>
      dispatch(getEventCalendarDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingCalendar);

