import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return createStyles({
      datepicker: {
        margin: "10px",
        width: "160px",
        borderRadius: "20px",
      },
      dottedDivider: {
        width: "100%",
        textAlign: "center",
        borderTop: "2px dotted" + theme.palette.divider,
        margin: "20px 0",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      countText: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        color: "rgba(0,0,0,0.38)!important",
        wordBreak: "break-word",
      },
      updateButton: {
        display: "block",
        textTransform: "capitalize",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "15px",
        backgroundColor: "rgb(25, 60, 109)",
      },
      cancelButton: {
        marginRight: "10px",
        display: "block",
        textTransform: "capitalize",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "15px",
      },
      updateButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
      },
      headerContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        marginRight:'12px'
      },
      createAccoutContainer: {
        display: "flex",
        alignItems: "center",
        height: "50%",
        width: "100%",
        justifyContent: "right",
        gap: "2px",
      },
      titletxt: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "18px!important",
        fontWeight: 500,
      },
      addLead: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        marginLeft: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "8%",
      },
      apply: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "35px",
        textTransform: "capitalize",
        marginLeft: "auto",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "40%",
      },
      column: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        marginRight: "10px",
        border: "0",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        width: "8%",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      upload: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        textTransform: "capitalize",
        border: "1px solid rgba(20, 56, 113, 1)",
        color: "#fff",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px",
        fontFamily: "Montserrat-Medium",
        fontSize: "14px",
        fontWeight: "bold",
        width: "8%",
        whiteSpace: "nowrap",
      },
      svgCreateAccout: {
        height: "33px",
        display: "flex",
        width: "20%",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        background: "#0279ff",
        boxShadow: "0 -2px 0 0 inset #0061ca!important",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
      },
      progressBarContainer: {
        position: "relative",
      },
      progressBar: {
        position: "absolute",
        top: "10%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  export default useStyles;