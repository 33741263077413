import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import dayjs from "dayjs";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import InstaLogo from "../../assets/images/instagram.png";
import LinkedInLogo from "../../assets/images/linkedin.png";
import { statusOptions } from '../Leads/constant';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Menu,
  Select,
  Autocomplete,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import { dashboardUI, leadDataUI, leadsDataEntity } from "../../reducers";
import { Key, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { dashboardEntity } from "../../reducers";
import {
  createFollowUpDataAPICall,
  updateFollowUpDataAPICall,
  getLeadsDataAPICall,
  getLeadsdetailsById,
  updateLeadDataAPICall,
  getDownloadLeadFile,
  getLeadsStatusDataAPICall,
} from "../../actions";
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import moment from "moment-timezone";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import SendEmail from "./SendEmail";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";
import { toast } from "react-toastify";
import LeadDetails from "../Leads/LeadDetails";
import Comment from "./Comment";

const getStatusColor = (status: string) => {
  switch (status) {
    case "New":
      return "#4CAF50";
    case "Dormant":
      return "#FF5252";
    case "Unsubscribe":
      return "#2196F3";
    default:
      return "#000000";
  }
};

interface SearchData {
  limit: number;
  page: number;
  searchString: string;
  follow_up_status: any;
  domain: any;
  fromDate?: string;
  toDate?: string;
  userIds?: any;
  country?: any;
  mailFilter?:any;
}

const DealsClosedTable = (props: any) => {
  const {
    rowsData,
    limit,
    userSearch,
    selectedDate,
    selectedToDate,
    selectedDomain1,
    filterOptions1,
    // selectedCountries,
    // selectedDomains,
    selectedFilterOptions,
    onSelectStatusChange,
    onSelectDomainChange,
    updateLeadDataAPICall,
    getDownloadLeadFileURL,
    DownloadableLeadFile,
    isDownloadableLeadFile,
    resetDownloadLeadFile,
    isBulkEmailSendDone,
    type,
    createFollowUpDataAPICall,
    isCreateFollowUpDataDone,
    getLeadsDataAPICall,
    getLeadsDetailsByIdAPICall,
    getLeadsStatusDataAPICall,
    getLeadsStatusData,
    resetCreateFollowUpData,
    resetUpdateLead,
    isGettingLeadsDataById,
    getLeadsDetailsById,
    openSendEmailDialog1,
    openUpdateStatusDialog,
    handleCloseStatusDialog,
    openSendEmailDialog2,
    isUpdateLeadDone,
    isChecked,
    isCheckedAll,
    onCheckboxChange,
    onSelectAll,
    apiCallTrigger,
    // handleOpenEmailDialog
  } = props;

  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [leadEmail, setLeadEmail] = useState<any>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [statusData, setStatusData] = useState("New");
  const [leadObjectId, setLeadId] = useState();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowIdForEmail, setSelectedRowIdForEmail] = useState<
    string | null
  >(null);
  const [selectedTime, setSelectedTime] = useState(
    moment().format("hh:mm:ss A")
  );
  const [checkedData, setCheckedData] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  const [dealSize, setDealSize] = useState("");

  // useEffect(()=>{
  //   getLeadsStatusDataAPICall()
  // },[])

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  // useEffect(()=>{
  //   getLeadsStatusDataAPICall()
  // },[])
  // useEffect(() => {
  //   if (isUpdateFollowUpDataDone) {
  //     const data = {
  //       email: leadEmail,
  //     }
  //     getFollowUpDataByIdAPICall(data)
  //     resetUpdateFollowUpDataAPICall()
  //     setIsEditDialogOpen(false)
  //   }
  // }, [isUpdateFollowUpDataDone])

  useEffect(() => {
  const safeSelectedFilterOptions1 = selectedFilterOptions && typeof selectedFilterOptions === 'object' ? selectedFilterOptions : {};

  const selectedCountries:any = [];
  const selectedDomains:any = [];

  Object.keys(safeSelectedFilterOptions1).forEach(key => {
    const option = safeSelectedFilterOptions1[key];
    if (option.checked) {
      const parent = filterOptions1.find((parent:any) => parent.children?.some((child:any) => child.key === key));
      if (parent) {
        switch (parent.key) {
          case 'country':
            selectedCountries.push(key);
            break;
          case 'domain':
            selectedDomains.push(key);
            break;
          default:
            break;
        }
      }
    }
  });
    const searchData: SearchData = {
      limit: limit,
      page: 1,
      // fromDate: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
      // toDate: moment(new Date()).format('YYYY-MM-DD'),
      follow_up_status: statusData,
      domain: selectedDomains.join(','),
      searchString: userSearch,
      country: selectedCountries.join(','),
      userIds: "",
      mailFilter: "",
    };

    if (userSearch === "") {
      searchData.fromDate = selectedDate;
      searchData.toDate = selectedToDate;
    }

    if (isCreateFollowUpDataDone || isUpdateLeadDone) {
      getLeadsDataAPICall(searchData);
      if (isCreateFollowUpDataDone) {
        setIsEditDialogOpen(false);
        toast.success("Comment added successfully");
        resetCreateFollowUpData();
      }
      resetUpdateLead();
      setSelectedRows([]);
      setCheckedData([]);
    }
  }, [isCreateFollowUpDataDone, isUpdateLeadDone]);

  useEffect(() => {
    if (openSendEmailDialog1) {
      setOpenSendEmailDialog(true);
    }
  }, [openSendEmailDialog1]);

  useEffect(() => {
    if (openUpdateStatusDialog) {
      setIsStatusDialogOpen(true);
    }
  }, [openUpdateStatusDialog]);

  useEffect(() => {
    if (isDownloadableLeadFile) {
      const link = document.createElement("a");
      link.href = DownloadableLeadFile?.downloadableUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resetDownloadLeadFile();
    }
  }, [isDownloadableLeadFile]);

  useEffect(() => {
    if (statusData === "Won" && !dealSize) {
      setIsUpdateDisabled(true);
    } else {
      setIsUpdateDisabled(false);
    }
  }, [statusData, dealSize]);

  const useStyles = makeStyles(() => {
    return createStyles({
      datepicker: {
        width: "240px",
      },
      inputLabel: {
        display: "flex",
        alignItems: "center",
      },
      timepicker: {
        width: "170px",
        marginLeft: "10px!important",
      },
      updateButton: {
        display: "block",
        width: "100px",
      },
      updateButtonContainer: {
        display: "flex",
        justifyContent: "right",
        marginTop: "10px",
      },
      titletext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "16px!important",
        fontWeight: 500,
      },
      headingtext: {
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      subheadingtext: {
        fontFamily: "Montserrat-Regular!important",
        fontSize: "12px!important",
        fontWeight: 500,
        wordBreak: "break-word",
      },
      addcomment: {
        display: "flex",
        justifyContent: "center",
      },
      disable: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      progressBar: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
  });

  const classes = useStyles();
  // const today = selectedDate ? new Date(selectedDate) : new Date()
  // const formattedDate = today.toLocaleDateString('en-US', {
  //   month: '2-digit',
  //   day: '2-digit',
  //   year: 'numeric',
  // })
  // const fromDate = dayjs(formattedDate)
  // const onhandleChangeForFromDate = (e: any) => {
  //   setSelctedDate(moment(new Date(e?.$d)).toISOString())
  //   setUserSearch('')
  // }

  const checkfilteredData = () => {
    if (rowsData?.length === 0) {
      return "data-not-available";
    } else {
      return "data-available";
    }
  };

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const handleAddIconClick = (email: any, leadId: any, status: any) => {
    // let comment;
    // setCommentData(comment)
    setLeadEmail(email);
    setLeadId(leadId);
    setStatusData(status);
    setIsEditDialogOpen(true);
  };
  const handleChangeForStatus = (event: any) => {
    const newValue = event.target.value;
    setStatusData(newValue);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    handleResetError();
  };

  const handleStatusDialogOpen = () => {
    setIsStatusDialogOpen(true);
  };

  const handleStatusDialogClose = () => {
    setIsStatusDialogOpen(false);
    handleCloseStatusDialog(false);
  };

  const handleRowClick = (id: any, fromEmailClick = false) => {
    let data = {
      leadId: id,
    };
    getLeadsDetailsByIdAPICall(data);
    setOpenDialog(true);
    setLeadId(data.leadId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSendEmailCloseDialog = () => {
    setOpenSendEmailDialog(false);
    openSendEmailDialog2(false);
  };

  const handleEditLeadClick = (event: any, id: any) => {
    event.stopPropagation();
    localStorage.setItem("previousPath", window.location.pathname);
    sessionStorage.setItem("selectedDate1", selectedDate);
    sessionStorage.setItem("selectedToDate1", selectedToDate);
    sessionStorage.setItem("selectedFilterOptions1", JSON.stringify(selectedFilterOptions) || "{}");
    navigate(`/home/dashboard/editlead/${id}`);
  };

  const selectedRow = rowsData?.find(
    (row: { id: string }) => row.id === selectedRowIdForEmail
  );
  const selectedEmailNames = selectedRow ? [selectedRow.first_name] : [];
  const selectedMailEmails = selectedRow ? [selectedRow.email] : [];

  const handleResetError = () => {
    setDealSize("");
    setStatusData("Won");
    setSelectedTime(moment().format("hh:mm:ss A"));
  };

  const handleUpdateStatus = () => {
    const leadStatusData = {
      id: [...selectedRows],
      follow_up_status: statusData,
      deal_size: dealSize || 0,
    };
    if (leadStatusData) {
      updateLeadDataAPICall(leadStatusData);
      setIsStatusDialogOpen(false);
      handleResetError();
    }
  };

  const resetSelectedRows = () => {
    setSelectedRows([]);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    const isChecked = event.target.checked;
    let data = { id: id, checked: isChecked };
    let tempData = checkedData;
    if (isChecked) {
      onCheckboxChange(isChecked);
      setCheckedData([...checkedData, data]);
    } else {
      tempData = tempData.filter((dd: any) => dd.id !== id);
      setCheckedData(tempData);
      if (tempData.length) {
        onCheckboxChange(true);
      } else {
        onCheckboxChange(false);
      }
    }

    setSelectedRows((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, id];
      } else {
        return prevSelected.filter((rowId) => rowId !== id);
      }
    });
  };

  useEffect(() => {
    setSelectedRows([]);
    setCheckedData([]);
  }, [apiCallTrigger, isBulkEmailSendDone]);

  const handleSelectAllClick = (event: any) => {
    const isCheckedAll = event.target.checked;
    onSelectAll(isCheckedAll);
    const allRowIds = rowsData?.map((row: { id: any }) => row.id);
    const checkedData: any = [];
    if (isCheckedAll) {
      rowsData?.forEach((row: any) => {
        let data = {
          id: row.id,
          checked: isCheckedAll,
        };
        checkedData.push(data);
      });
      setCheckedData(checkedData);
      onCheckboxChange(true);
    } else {
      setCheckedData([]);
      onCheckboxChange(false);
    }

    setSelectedRows((prevSelected) => {
      if (prevSelected?.length === allRowIds?.length) {
        return [];
      } else {
        return allRowIds;
      }
    });
  };

  // const handleLogSelectedData = () => {
  //   const selectedRowsData = rowsData.filter((row: { id: string; }) => selectedRows.includes(row.id));
  //   const selectedNames = selectedRowsData.map((row: { name: any; }) => row.name);
  //   const selectedEmails = selectedRowsData.map((row: { email: any; }) => row.email);

  // };

  const handleCellClick = (event: any) => {
    event.stopPropagation();
  };

  switch (checkfilteredData()) {
    case "data-not-available":
      return (
        <>
          <TableContainer>
            <Table>
              <TableHead
                sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
              >
                <TableRow>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Lead Name</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Phone</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Email</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Lead Country</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Company</span>
                  </TableCell>
                  {/* <TableCell>
                    <span className='mail-info'>Source</span>
                  </TableCell> */}
                  {/* <TableCell>
                    <span className='mail-info'>Assignee</span>
                  </TableCell> */}
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Domain</span>
                  </TableCell>
                  {/* <TableCell>
                    <span className='mail-info'>Technology</span>
                  </TableCell> */}
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Status</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Deal Size</span>
                  </TableCell>
                  <TableCell sx={{padding:'6px 16px'}}>
                    <span className='mail-info'>Next FollowUp Date</span>
                  </TableCell>
                  <TableCell sx={{ padding: "6px 16px" }}>
                    <span className="mail-info">Actions</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "#ffffff" }}>
                <TableRow>
                  <TableCell colSpan={12}>
                    <div className="no-data-outer-table">
                      No data available.
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    case "data-available":
      return (
        <>
          <div style={{ overflowX: "auto", maxWidth: "100%", minHeight: 230 }}>
            <TableContainer>
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgb(25, 60, 109)", color: "#fff" }}
                >
                  <TableRow>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={selectedRows?.length === rowsData?.length}
                        onChange={(event) => handleSelectAllClick(event)}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Lead Name</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Phone</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Email</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Lead Country</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Company</span>
                    </TableCell>
                    {/* <TableCell>
                      <span className='mail-info'>Source</span>
                    </TableCell> */}
                    {/* <TableCell>
                      <span className='mail-info'>Assignee</span>
                    </TableCell> */}
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Domain</span>
                    </TableCell>
                    {/* <TableCell>
                      <span className='mail-info'>Technology</span>
                    </TableCell> */}
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Status</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Deal Size</span>
                    </TableCell>
                    <TableCell sx={{padding:'6px 16px'}}>
                      <span className='mail-info'>Deal Closed Date</span>
                    </TableCell>
                    <TableCell sx={{padding:'6px 16px'}}>
                      <span className='mail-info'>Created Date</span>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 16px" }}>
                      <span className="mail-info">Actions</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsData?.length ? (
                    rowsData?.map(
                      (data: any, index: Key | null | undefined) => (
                        <TableRow
                          key={index}
                          sx={{ cursor: "pointer", background: "#ffffff" }}
                          onClick={() => handleRowClick(data.id)}
                        >
                          <TableCell
                            onClick={handleCellClick}
                            sx={{ padding: "0px 16px" }}
                          >
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              checked={selectedRows.includes(data.id)}
                              onChange={(event) =>
                                handleCheckboxChange(event, data.id)
                              }
                            />
                          </TableCell>
                          <TableCell className="tablebodycell">
                            {data?.name ? data?.name : ""}
                          </TableCell>
                          <TableCell className="tablebodycell">
                            {data?.leadPhone ? data?.leadPhone : "-"}
                          </TableCell>
                          <TableCell className="tablebodycell">
                            {data?.email.split(",")?.length > 1
                              ? data?.email.split(",")[0] + "..."
                              : data?.email}
                          </TableCell>
                          <TableCell className="tablebodycell">
                            {data?.leads_country ? data?.leads_country : "-"}
                          </TableCell>
                          <TableCell className="tablebodycell">
                            {data?.company ? 
                            (data.company.length > 30 ? `${data.company.slice(0, 30)}...` : data.company)
                            : "-"}
                          </TableCell>
                          <TableCell className="tablebodycell">
                            {data?.domain ? data?.domain : "-"}
                          </TableCell>
                          <TableCell
                            className="tablebodycell"
                            sx={{
                              color: getStatusColor(data.follow_up_status),
                            }}
                          >
                            {data?.follow_up_status || "-"}
                          </TableCell>
                          <TableCell
                            className="tablebodycell"
                          >
                            {data?.deal_size ? "$" : ""}
                            {data?.deal_size || "-"}
                          </TableCell>
                        <TableCell className='tablebodycell'>
                          {data?.followUpsNextDate ? moment(data?.followUpsNextDate).format('MM/DD/YYYY')
                            : 'NA'}
                        </TableCell>
                        <TableCell className='tablebodycell'>
                          {data?.followUpsDate ? moment(data?.followUpsDate).format('MM/DD/YYYY')
                            : 'NA'}
                          </TableCell>
                          <TableCell
                            onClick={handleCellClick}
                            sx={{
                              fontFamily: "Montserrat-Medium",
                              fontSize: "12px",
                              padding: "0px 16px",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {/* <button
                              onClick={(event) => handleSendEmailClick(event, data.id)}
                              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                            >
                              <EmailOutlinedIcon
                                sx={{ marginTop: '5px', color: '#FFA500' }}
                                className={classes.actionbtn}
                              />
                            </button> */}
                              <button
                                onClick={(event) => {
                                  stopPropagation(event);
                                  handleAddIconClick(
                                    data?.email,
                                    data?.id,
                                    data?.follow_up_status
                                  );
                                }}
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  paddingLeft: "0px",
                                  paddingTop: "3px",
                                  paddingRight: "2px",
                                }}
                              >
                                <Tooltip title="Add Comment">
                                  <ModeCommentOutlinedIcon
                                    color="primary"
                                    sx={{ marginTop: "5px" }}
                                    className={classes.actionbtn}
                                  />
                                </Tooltip>
                              </button>
                              <button
                                onClick={(event) =>
                                  handleEditLeadClick(event, data.id)
                                }
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  padding: "0",
                                }}
                              >
                                <Tooltip title="Edit Lead">
                                  <EditOutlinedIcon
                                    sx={{ marginTop: "5px", color: "#4CAF50" }}
                                    className={classes.actionbtn}
                                  />
                                </Tooltip>
                              </button>
                              <Tooltip
                                title={data?.source ? data?.source : "-"}
                              >
                                <AttachMoneyOutlinedIcon
                                  sx={{ marginTop: "5px" }}
                                  className={classes.actionbtn}
                                />
                              </Tooltip>
                              {/* <DeleteOutlinedIcon
                              className={classes.actionbtn}
                              sx={{ color: '#ff0000' }}
                            /> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "120px",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress color="primary" />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <Dialog
            open={isEditDialogOpen}
            onClose={handleEditDialogClose}
            fullWidth
            maxWidth={false}
            PaperProps={{
              style: {
                width: "55%",
                maxWidth: "55%",
                margin: "auto",
              },
            }}
          >
            <DialogTitle className={classes.addcomment}>
              Add Comment
              <IconButton
                aria-label="close"
                onClick={handleEditDialogClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="divider" />
            <DialogContent className="comment-dialog">
              <Comment 
              leadEmail={leadEmail}
              statusData={statusData}
              leadObjectId={leadObjectId}
              handleEditDialogClose={handleEditDialogClose}
              />
            </DialogContent>
          </Dialog>

          <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "80%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle className={classes.titletext} sx={{ marginLeft: "45%" }}>
          Lead Details
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
        <DialogContent sx={{ paddingTop: "10px" }}>
              <LeadDetails leadObjectId={leadObjectId}/>
        </DialogContent>
      </Dialog>

          <Dialog
            open={isStatusDialogOpen}
            onClose={handleSendEmailCloseDialog}
            fullWidth
            maxWidth={false}
            PaperProps={{
              style: {
                width: "30%",
                maxWidth: "30%",
              },
            }}
          >
            <DialogTitle className={classes.addcomment}>
              Update Status
              <IconButton
                aria-label="close"
                onClick={handleStatusDialogClose}
                sx={{
                  position: "absolute !important",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider className="divider" />
            <DialogContent sx={{ padding: "10px 15px !important" }}>
              <div style={{ width: "100%", marginTop: "6px" }}>
                <TextField
                  select
                  id="demo-simple-select"
                  value={statusData}
                  label="Status"
                  variant="outlined"
                  onChange={handleChangeForStatus}
                  InputProps={{ sx: { borderRadius: 20 } }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "300px", // Set your desired height here
                        },
                      },
                    },
                  }}
                >
                  {statusOptions
                  .filter(option => option !== 'Won')
                  .map((option:any) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="deal-size"
                  value={dealSize}
                  label="Deal Size ($)"
                  variant="outlined"
                  InputLabelProps={{
                    style: { top: "-6px" },
                  }}
                  InputProps={{ sx: { borderRadius: 20 } }}
                  onChange={(e) => setDealSize(e.target.value)}
                  required={statusData === "Won"}
                  sx={{ marginTop: 2, borderRadius: 20 }}
                />

                <div className={classes.updateButtonContainer}>
                  <Button
                    className={classes.cancelbtn}
                    // variant='contained'
                    color="primary"
                    onClick={() => handleStatusDialogClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={clsx(
                      { [classes.disable]: !isChecked },
                      classes.updateButton
                    )}
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      fontFamily: "Montserrat-SemiBold",
                      fontSize: "14px",
                    }}
                    color="primary"
                    onClick={() => handleUpdateStatus()}
                    disabled={isUpdateDisabled}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
        open={openSendEmailDialog}
        onClose={handleSendEmailCloseDialog}
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: "65%",
            maxWidth: "65%",
          },
        }}
      >
        <DialogTitle
        sx={{ padding: "10px 15px !important" }} 
        className={classes.addcomment}>
          Send Email
          <IconButton
            aria-label="close"
            onClick={handleSendEmailCloseDialog}
            sx={{
              position: "absolute !important",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider" />
            <DialogContent sx={{ padding: "10px 15px !important" }}>
              <SendEmail
                selectedNames={selectedRows?.map((rowId) => {
                  const row = rowsData?.find(
                    (row: { id: string }) => row.id === rowId
                  );
                  return row?.first_name || "";
                })}
                selectedEmails={selectedRows?.map((rowId) => {
                  const row = rowsData?.find(
                    (row: { id: string }) => row.id === rowId
                  );
                  return row?.email || "";
                })}
                selectedEmailNames={selectedEmailNames}
                selectedMailEmails={selectedMailEmails}
                handleClose={handleSendEmailCloseDialog}
              />
            </DialogContent>
          </Dialog>
        </>
      );
  }
};

const mapStateToProps = (state: any) => {
  return {
    // getFollowUpDataById: dashboardEntity.getDashboard(state).getFollowUpDataById,
    isUpdateFollowUpDataDone:
      dashboardEntity.getDashboard(state).isUpdateFollowUpDataDone,
    isCreateFollowUpDataDone:
      dashboardUI.getDashboard(state).isCreateFollowupDataDone,
    isCreateFollowUpData:
      dashboardEntity.getDashboard(state).isCreateFollowUpData,
    getLeadsData: leadsDataEntity.getLeads(state).getLeadsData,
    getLeadsStatusData: leadsDataEntity.getLeads(state).getLeadsStatusData,
    getLeadsDetailsById: leadsDataEntity.getLeads(state).getLeadsDetailsById,
    DownloadableLeadFile: leadsDataEntity.getLeads(state).getDownloadLeadFile,
    isGettingLeadsDataById: leadDataUI.getLeads(state).isGettingLeadsDataById,
    isBulkEmailSending: dashboardUI.getDashboard(state).isBulkEmailSending,
    isBulkEmailSendDone: dashboardUI.getDashboard(state).isBulkEmailSendDone,
    isUpdateLeadDone: dashboardUI.getDashboard(state).isUpdateLeadDone,
    isDownloadableLeadFile: leadDataUI.getLeads(state).isDownloadableLeadFile,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getLeadsDataAPICall: (data: any) =>
      dispatch(getLeadsDataAPICall.request(data)),
    getLeadsStatusDataAPICall: (data: any) =>
      dispatch(getLeadsStatusDataAPICall.request(data)),
    updateFollowUpDataAPICall: (data: any) =>
      dispatch(updateFollowUpDataAPICall.request(data)),
    // getFollowUpDataByIdAPICall: (data: any) => dispatch(getFollowUpDataByIdAPICall.request(data)),
    createFollowUpDataAPICall: (data: any) =>
      dispatch(createFollowUpDataAPICall.request(data)),
    getLeadsDetailsByIdAPICall: (data: any) =>
      dispatch(getLeadsdetailsById.request(data)),
    getDownloadLeadFileURL: (data: any) =>
      dispatch(getDownloadLeadFile.request(data)),
    updateLeadDataAPICall: (data: any) =>
      dispatch(updateLeadDataAPICall.request(data)),
    resetCreateFollowUpData: () => dispatch(createFollowUpDataAPICall.reset()),
    resetUpdateFollowUpDataAPICall: () =>
      dispatch(updateFollowUpDataAPICall.reset()),
    resetUpdateLead: () => dispatch(updateLeadDataAPICall.reset()),
    resetDownloadLeadFile: () => dispatch(getDownloadLeadFile.reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsClosedTable);
